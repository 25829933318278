import React from 'react'

import { Consultation } from '../components'
import { BannerData } from '../mock/bannerAndFlexibleData'

export const Banner: React.FC = () => {
  return (
    <section className="banner">
      <div className="banner-img">
        <img src={BannerData.img} alt={BannerData.title} />
      </div>
      <div className="banner-desc">
        <h1>{BannerData.title}</h1>
        <p>{BannerData.desc}</p>
        <Consultation />
      </div>
    </section>
  )
}
