import { ISection, ITemplate, SectionTypes, TSocial } from '@/types'

import { getFile } from '@/utils'

import {
  EditRewardSection,
  EditAdvanceSection,
  EditAdvantagesSection,
} from './components'

export const Section = (key: SectionTypes) => {
  switch (key) {
    case SectionTypes.ADVANCE:
      return EditAdvanceSection
    case SectionTypes.ADVANTAGES:
      return EditAdvantagesSection
    case SectionTypes.REWARD:
      return EditRewardSection
    default:
      return null
  }
}

const defaultBlocks = [
  {
    title: undefined,
    image: undefined,
    description: undefined,
  },
]

const formatter = (section: ISection | undefined) => {
  if (!section) return undefined
  return {
    ...section,
    image: {
      file: '',
      path: section?.image ? getFile(section.image) : undefined,
    },
    blocks: (section?.blocks ?? defaultBlocks)?.map(block => ({
      ...block,
      image: {
        file: undefined,
        path: block?.image ? getFile(block.image) : undefined,
      },
    })),
  }
}

export const formatSections = (
  sectionTypes: SectionTypes[],
  template?: ITemplate,
) => {
  return sectionTypes.map(_v => ({
    ...(formatter(template?.sections?.find(_s => _s.type === _v)) ?? {
      image: undefined,
      title: undefined,
      blocks: defaultBlocks,
      description: undefined,
    }),
    type: _v,
  }))
}

export const formatSocials = (template?: ITemplate) => {
  return Object.values(TSocial).map(value => ({
    ...(template?.socials?.find(_v => value === _v.type) ?? {
      link: '',
      type: value,
    }),
  }))
}
