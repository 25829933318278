import {
  IResponse,
  IStartEnroll,
  IFinishPayload,
  IAcceptPayload,
  IPlanResponse,
  ICheckCoursePayload,
  ICreateCoursePayload,
  IStartedItemsResponse,
  IUpdateCourseAccessibilityPayload,
  ISinglePlanResponse,
} from '@/types'

import { useLazyGet, usePost, usePut } from '../utils/wrapper'
import endpoints from '../endpoints'

export const useGetCourses = (onSuccess: (_data: IPlanResponse) => void) => {
  return useLazyGet<undefined, IPlanResponse>(endpoints.courses, undefined, {
    onSuccess,
  })
}

export const useCreateCourse = () => {
  return usePost<ICreateCoursePayload, IResponse>(endpoints.createCourse)
}

export const useUpdateCourse = () => {
  return usePut<ICreateCoursePayload, IResponse>(endpoints.updateCourse)
}

export const useStartCourse = () => {
  return usePost<ICheckCoursePayload, IStartEnroll>(
    endpoints.startCourse,
    undefined,
  )
}

export const useGetCourseById = (
  onSuccess: (_data: ISinglePlanResponse) => void,
) => {
  return useLazyGet<ICheckCoursePayload, ISinglePlanResponse>(
    endpoints.courseById,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useMyCourses = (
  onSuccess: (_data: IStartedItemsResponse) => void,
) => {
  return useLazyGet(endpoints.boughtCourses, undefined, {
    onSuccess,
  })
}

export const useFinishCourse = () => {
  return usePost<IFinishPayload, IResponse>(endpoints.finishCourse)
}

export const useAcceptDocument = () => {
  return usePost<IAcceptPayload>(endpoints.acceptDocument)
}

export const useUpdateCourseAccessibility = () => {
  return usePut<IUpdateCourseAccessibilityPayload, IResponse>(
    endpoints.updateCourseAccessibility,
  )
}

export const useAddCourseToAffiliate = () => {
  return usePost<ICheckCoursePayload, IResponse>(endpoints.addCourse)
}

export const useRemoveCourseFromAffiliate = () => {
  return usePost<ICheckCoursePayload, IResponse>(endpoints.removeCourse)
}

export const useMergeAffiliateCourse = () => {
  return usePost<ICheckCoursePayload, IResponse>(endpoints.mergeCourse)
}
