import React from 'react'

import { Button } from '@/components'

import GetInfoImg from '@/assets/images/get-info-img.webp'

export const GetInformation: React.FC = () => {
  return (
    <section className="get-information">
      <div className="info-img">
        <img src={GetInfoImg} alt="img" />
      </div>
      <div className="information">
        <h2>Get Enrolled Today</h2>
        <div className="info-desc">
          <p>
            Anointed By God Ministries (ABG) has more than five years of
            experience in the industry, which has helped us streamline the
            certification exam process. While other institutions charge
            thousands of dollars for certification exams, our rates are about
            80% less.
          </p>
          <p>
            Whether you desire to achieve more education or you desire to go
            into the workforce, our diploma will help you achieve your next leap
            forward.
          </p>
          <b>
            ABG offers a High Diploma Program, Chaplain Certification, and
            Bachelor’s or Master’s of Ministry Degree programs.
          </b>
        </div>
        <div className="button-style button-center">
          <Button type="button" className="bg-green-light">
            Courses
          </Button>
        </div>
      </div>
    </section>
  )
}
