import React from 'react'
import { NavLink } from 'react-router-dom'

import { Menu } from '../AppHeader/components'

import { LocationDetails } from './components'

import youtubeIcon from '@/assets/images/youtube.png'
import { signInPath } from '@/utils'

export const AppFooter: React.FC = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-inner-container container">
          <div className="footer">
            <div className="about">
              <h2>About Education Central</h2>
              <p>
                After successful course completion, you are able to download
                your certificate or diploma. Students are able to log in to
                their account to view certificates and completed courses.
              </p>
            </div>
            <div className="location">
              <h3>Contact Details:</h3>
              <div>
                <p>
                  128 Mallory <br /> Jersey City, NJ 07304
                </p>
                <p>
                  Phone: <a href="tel:718-913-5133">718-913-5133</a>
                </p>
                <p>
                  <a href="mailto:info@educationcentralapp.com">
                    info@educationcentralapp.com
                  </a>
                </p>
              </div>
              <LocationDetails />
              <div className="connect">
                <a
                  href="https://www.youtube.com/channel/UCDUKtTz2h9OoxvZyqhjiFbA/about"
                  target="_blank"
                >
                  <img src={youtubeIcon} alt="youtube" />
                </a>
              </div>
              <div className="copyright">
                <p>Copyright © 2023 Education Central. All Rights Reserved.</p>
                <a href="http://www.hodlercapitalgroup.com" target="_blank">
                  Designed by Hodler Capital Group
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-menu">
        <div className="footer-inner-container container">
          <div className="menu-item">
            <Menu hideProfile />
            <NavLink to={signInPath}>Login</NavLink>
          </div>
        </div>
      </div>
    </footer>
  )
}
