import { Outlet } from 'react-router-dom'

export const isActiveLink = (locationPath: string, path: string): boolean => {
  const _path = locationPath.split('/')

  return _path.includes(path)
}

export const generatePath = (
  path: string,
  params: { [x: string]: string | number | undefined | null } = {},
  queryData?: Record<string, string | number | boolean>[],
  fullUrl?: boolean,
): string => {
  let newPath: string = path
  Object.keys(params).forEach(param => {
    newPath = newPath.replace(`:${param}`, `${params[param] || ''}`)
  })

  if (queryData && queryData.length > 0) {
    const query = queryData
      .map(item => Object.keys(item)[0] + '=' + Object.values(item)[0])
      .join('&')
    newPath = newPath + '?' + query
  }

  if (fullUrl) {
    const baseUrl = `${window.location.protocol}//${window.location.host}`
    return `${baseUrl}${newPath}`
  }

  return newPath
}

export const withOutlet = (
  Comp: (_props: { children: React.ReactNode }) => JSX.Element,
) => (
  <Comp>
    <Outlet />
  </Comp>
)

export const getSubdomain = () => {
  // Get the current hostname
  const hostname = window.location.hostname

  // Check if it's localhost
  if (
    hostname === 'localhost' ||
    hostname.startsWith('127.0') ||
    hostname.startsWith('unitylearn')
  ) {
    return null // No subdomain on localhost
  }

  // Split hostname by dots (.)
  const parts = hostname.split('.')

  // If there are at least two parts, the first one is the subdomain
  if (parts.length >= 2) {
    return parts[0]
  }

  // No subdomain found
  return null
}

export const homePath = '/'
export const signInPath = '/sign-in'
export const signUpPath = '/sign-up'
export const redirectPath = '/redirect'
export const forgotPath = '/forgot-password'
export const welcomePath = '/welcome'
export const profilePath = '/profile'
export const referralPath = '/referral'
export const referralStudentAffiliatePath = '/referral/student-affiliate'
export const enrollmentFormPath = '/enrollment-form'
export const chargeAccountPath = '/charge'
export const resetPasswordPath = '/reset-password'
export const supportPath = '/support'
export const createAffiliateFormPath = '/create-affiliate-form'
export const affiliatePricingPath = '/affiliate-pricing'

export const testsPath = '/tests'
export const myTestsPath = '/my-tests'
export const coursePath = '/courses'
export const usersPath = '/users'
export const consultationsPath = '/consultations'
export const affiliatesPath = '/affiliates'
export const myAffiliatePath = '/affiliate'
export const editTemplatePath = 'template'
export const feedbackPath = '/feedbacks'
export const myCoursePath = '/my-courses'
export const documentsPath = '/documents'
export const communityPath = '/communities'
export const enrollmentsPath = '/enrollments'
export const manageCoursePath = '/manage-course'
export const manageQuizPath = '/manage-test'
export const newPath = 'new'
export const rolesPath = '/roles'
export const editPath = 'edit'
export const previewPath = 'preview'
export const honoraryDoctoratePath = '/doctorate'
export const honoraryDoctoralListPath = '/doctoral-list'
export const paymentHistoryPath = '/payment-history'

// Proctor paths
export const proctorTasksPath = '/proctor-tasks'
export const proctorProfilePath = '/proctor-profile'
export const proctorCalendarPath = '/proctor-calendar'
export const proctorTasksHistoryPath = '/proctor-tasks-history'

export const publicPaths: string[] = [
  homePath,
  signInPath,
  forgotPath,
  signUpPath,
  resetPasswordPath,
  honoraryDoctoratePath,
  createAffiliateFormPath,
]

const adminCommonPaths: string[] = [
  manageQuizPath,
  manageCoursePath,
  `${manageQuizPath}/${newPath}`,
  `${manageQuizPath}/${editPath}`,
  `${manageCoursePath}/${newPath}`,
  `${manageCoursePath}/${editPath}`,
  `${manageQuizPath}/${previewPath}`,
  `${manageCoursePath}/${previewPath}`,
]

export const adminPaths: string[] = [
  ...adminCommonPaths,
  supportPath,
  usersPath,
  `${usersPath}/${editPath}`,
  consultationsPath,
  affiliatesPath,
  referralPath,
  feedbackPath,
  documentsPath,
  enrollmentsPath,
  paymentHistoryPath,
  rolesPath,
  `${rolesPath}/${editPath}`,
  honoraryDoctoralListPath,
  referralStudentAffiliatePath,
  `${affiliatesPath}/${newPath}`,
  `${affiliatesPath}/${previewPath}`,
]

export const affiliateOwnerPaths: string[] = [
  ...adminCommonPaths,
  supportPath,
  usersPath,
  `${usersPath}/${editPath}`,
  referralPath,
  referralStudentAffiliatePath,
  feedbackPath,
  documentsPath,
  enrollmentsPath,
  honoraryDoctoralListPath,
  rolesPath,
  `${rolesPath}/${editPath}`,
  myAffiliatePath,
  paymentHistoryPath,
  `${myAffiliatePath}/${editTemplatePath}`,
]

export const studentPaths: string[] = [
  testsPath,
  coursePath,
  supportPath,
  affiliatePricingPath,
  profilePath,
  referralPath,
  referralStudentAffiliatePath,
  myTestsPath,
  myCoursePath,
  communityPath,
  documentsPath,
  paymentHistoryPath,
]

export const proctorPaths: string[] = [
  proctorTasksPath,
  proctorProfilePath,
  proctorCalendarPath,
  proctorTasksHistoryPath,
]
