import React from 'react'

import { AppLayout } from '@/layout'
import { Templates } from '@/types'
import { useBrand } from '@/hooks'

import {
  Help,
  Review,
  Banner,
  WhatWeDo,
  Flexible,
  CountInfo,
  OurMission,
  StayConnected,
  GetInformation,
  HomeLayout,
} from './components'
import {
  DefaultTemplate,
  DefaultTemplateFooter,
  DefaultTemplateHeader,
} from '../Templates'

// @ts-ignore
const Headers: Record<Templates, React.FC> = {
  default_template: DefaultTemplateHeader,
}

// @ts-ignore
const Footers: Record<Templates, React.FC> = {
  default_template: DefaultTemplateFooter,
}

// @ts-ignore
const PagesContent: Record<Templates, React.FC> = {
  default_template: DefaultTemplate,
}

export const HomePage: React.FC = () => {
  const { template, isAffiliate } = useBrand()

  if (isAffiliate && template?.name) {
    const Header = Headers[template?.name]
    const Footer = Footers[template?.name]
    const Content = PagesContent[template?.name]
    return (
      <HomeLayout Header={Header} Footer={Footer}>
        <Content />
      </HomeLayout>
    )
  }

  return (
    <AppLayout>
      <Banner />
      <CountInfo />
      <OurMission />
      <WhatWeDo />
      <Flexible />
      <Help />
      <GetInformation />
      <Review />
      <StayConnected />
    </AppLayout>
  )
}
