import React from 'react'
import { stayConnected } from '../mock/stayConnected'

interface ConnectedItem {
  icon: string
}

export const StayConnected: React.FC = () => {
  return (
    <div className="stay-connected container">
      <div className="stay-connected-inner">
        <h2>Stay Connected</h2>
        <p>Follow us on Social Media</p>
        <div className="connectedItem">
          {stayConnected.map((connectedItem: ConnectedItem, ind: number) => {
            return <StayConnectedItem key={ind} icon={connectedItem.icon} />
          })}
        </div>
      </div>
    </div>
  )
}

interface StayConnectedItemProps {
  icon: string
}

const StayConnectedItem: React.FC<StayConnectedItemProps> = ({ icon }) => {
  return (
    <div className="connectedItemBg">
      <span className={icon}></span>
    </div>
  )
}
