import React, { useState } from 'react'

import { PaymentContent, MapContent, HoursContent } from './'

type MenuItem = 'map' | 'hours' | 'payment'

export const LocationDetails: React.FC = () => {
  const [activeItem, setActiveItem] = useState<MenuItem>('map')

  const handleMenuItemClick = (item: MenuItem) => {
    if (item === activeItem && item === 'map') {
      setActiveItem('map')
    } else {
      setActiveItem(item)
    }
  }

  return (
    <div className="location-details">
      <div className="menu">
        <ul>
          <li onClick={() => handleMenuItemClick('map')}>Map</li>
          <li onClick={() => handleMenuItemClick('hours')}>Hours</li>
          <li onClick={() => handleMenuItemClick('payment')}>
            Payments Accepted
          </li>
        </ul>
      </div>
      <div className="menu-content">
        {activeItem === 'map' && <MapContent />}
        {activeItem === 'hours' && <HoursContent />}
        {activeItem === 'payment' && <PaymentContent />}
      </div>
    </div>
  )
}
