import React from 'react'

interface IHomeLayoutProps {
  children: React.ReactNode
  Header?: React.ElementType
  Footer?: React.ElementType
}

export const HomeLayout: React.FC<IHomeLayoutProps> = ({
  Header,
  Footer,
  children,
}) => {
  return (
    <div className="templates">
      {!!Header && <Header />}
      {children}
      {!!Footer && <Footer />}
    </div>
  )
}
