export const stayConnected = [
  {
    icon: 'ic-facebook',
  },
  {
    icon: 'ic-x',
  },
  {
    icon: 'ic-instagram',
  },
]
