import React from 'react'

import { review } from '../mock/review'

import connectedBg from '@/assets/images/connected-bg.webp'

export const Review: React.FC = () => {
  return (
    <>
      <div className="connected-bg">
        <img src={connectedBg} alt="Connected Bg" />

        <div className="connected-desc">
          <h2>What People are Saying About Us</h2>

          <div className="connected-desc-review">
            {review.map((reviewItem, ind) => {
              return <ReviewItem key={ind} reviewItem={reviewItem} />
            })}
          </div>
        </div>
      </div>
    </>
  )
}

interface ReviewItemProps {
  reviewItem: {
    review: string
    reviewer: string
  }
}

const ReviewItem: React.FC<ReviewItemProps> = ({ reviewItem }) => {
  return (
    <div className="review-item">
      <span className="star">★★★★★</span>
      <div className="review-content">
        <p>{`"${reviewItem.review}"`}</p>
        <span>{` - ${reviewItem.reviewer}`}</span>
      </div>
    </div>
  )
}
