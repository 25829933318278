import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react'
import {
  Location,
  useNavigate,
  useLocation,
  useSearchParams,
  NavigateFunction,
} from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'

import {
  useGetMe,
  useSignIn,
  useSignUp,
  useUploadAvatar,
  useResetPassword,
  useForgotPassword,
  useCreateEnrollment,
  useChooseRole,
} from '@/apis'
import {
  IUser,
  TResetSchema,
  IDecodedToken,
  IForgotPayload,
  ISignInPayload,
  ISignUpPayload,
  IEnrollmentPayload,
  IRole,
  ISubscription,
} from '@/types'
import { useToast } from '@/hooks'
import {
  homePath,
  signInPath,
  publicPaths,
  welcomePath,
  getStorageValue,
  setStorageValue,
  // chargeAccountPath,
  removeStorageValue,
  resetPasswordPath,
  // enrollmentFormPath,
} from '@/utils'
import { AUTH_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@/constants'

interface IAuthContext {
  me?: IUser
  isAdmin: boolean
  requests?: number
  meLoading: boolean
  isProctor: boolean
  resetToken?: string
  onSignOut: () => void
  authenticated: boolean
  isSchoolOwner: boolean
  hasPaymentModal: boolean
  tokenInfo?: IDecodedToken
  clientSecret: null | string
  createEnrollmentLoading: boolean
  onUploadAvatar: (_file: File) => void
  onChooseRole: (_payload: IRole) => void
  onReset: (_payload: TResetSchema) => void
  onUpdateRequests: (_count: number) => void
  setSecret: (_secret: string | null) => void
  togglePaymentModal: (_bool: boolean) => void
  onSignUp: (_payload: ISignUpPayload) => void
  onForgot: (_payload: IForgotPayload) => void
  onSignIn: (_payload: ISignInPayload) => void
  onSetSubscription: (_payload: ISubscription | null) => void
  onUpdateProfile: (_payload: IEnrollmentPayload, _cb?: () => void) => void
}

export const AuthContext = createContext<IAuthContext>({
  isAdmin: false,
  isSchoolOwner: false,
  isProctor: false,
  meLoading: false,
  clientSecret: null,
  tokenInfo: undefined,
  authenticated: false,
  hasPaymentModal: false,
  onReset: () => undefined,
  onForgot: () => undefined,
  onSignUp: () => undefined,
  onSignIn: () => undefined,
  onSignOut: () => undefined,
  setSecret: () => undefined,
  onChooseRole: () => undefined,
  createEnrollmentLoading: false,
  onUploadAvatar: () => undefined,
  onUpdateProfile: () => undefined,
  onUpdateRequests: () => undefined,
  togglePaymentModal: () => undefined,
  onSetSubscription: () => undefined,
})

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate: NavigateFunction = useNavigate()
  const { addToast } = useToast()
  const [me, setMe] = useState<IUser>()
  const [queryParams] = useSearchParams()
  const [requests, setRequests] = useState(0)
  const { pathname, search }: Location = useLocation()
  const [resetToken, setResetToken] = useState<string>()
  const [authenticated, setAuthenticated] = useState(false)
  const [tokenInfo, setTokenInfo] = useState<IDecodedToken>()
  const [hasPaymentModal, setShowPaymentModal] = useState(false)
  const [clientSecret, setClientSecret] = useState<string | null>(null)

  const togglePaymentModal = useCallback((_bool: boolean) => {
    setShowPaymentModal(_bool)
  }, [])

  const setSecret = useCallback((_secret: string | null) => {
    setClientSecret(_secret)
  }, [])

  const _resetToken = queryParams.get('reset_token')
  const authToken = getStorageValue(AUTH_TOKEN_KEY)
  const refreshToken = getStorageValue(REFRESH_TOKEN_KEY)

  const onFetchMeSuccess = useCallback((data: IUser) => {
    setMe(data)
  }, [])

  const [fetchMe, { isLoading: meLoading }] = useGetMe(onFetchMeSuccess)
  const { mutateAsync: uploadAvatar } = useUploadAvatar()
  const { mutateAsync: createEnrollment, isPending: createEnrollmentLoading } =
    useCreateEnrollment()

  useEffect(() => {
    if (_resetToken) {
      setResetToken(_resetToken)
      navigate(resetPasswordPath)
    }
  }, [_resetToken, navigate])

  useEffect(() => {
    // disable public paths when loggedIn
    if (publicPaths.includes(pathname) && !authenticated) {
      navigate(pathname + search)
    }
  }, [navigate, pathname, authenticated, search])

  const onSetSubscription = useCallback(
    (subscription: ISubscription | null) => {
      setMe(prev => (prev ? { ...prev, subscription } : undefined))
    },
    [],
  )

  const onSignOut = useCallback(() => {
    removeStorageValue(AUTH_TOKEN_KEY)
    removeStorageValue(REFRESH_TOKEN_KEY)
    navigate(homePath, { replace: true })
    setAuthenticated(false)
    setTokenInfo(undefined)
    setMe(undefined)
  }, [navigate])

  const onAuthenticated = useCallback(
    (_authenticated: boolean) => {
      setAuthenticated(_authenticated)
      if (_authenticated) {
        fetchMe()
        const to =
          pathname.includes(signInPath) || publicPaths.includes(pathname)
            ? welcomePath
            : pathname
        navigate(to, { replace: true })
      } else {
        onSignOut()
      }
    },
    [fetchMe, pathname, navigate, onSignOut],
  )

  // useEffect(() => {
  //   if (!me) return
  //   if (!me?.charged || !me.hasEnrollment) {
  //     navigate(!me?.hasEnrollment ? enrollmentFormPath : chargeAccountPath)
  //   }
  // }, [me, navigate, pathname])

  const { mutateAsync: signUp } = useSignUp()
  const { mutateAsync: signIn } = useSignIn()
  const { mutateAsync: reset } = useResetPassword()
  const { mutateAsync: forgot } = useForgotPassword()
  const { mutateAsync: chooseRole } = useChooseRole()

  const onUpdateProfile = useCallback(
    async (payload: IEnrollmentPayload, cb?: () => void) => {
      try {
        const response = await createEnrollment(payload)
        if (response.data.success) {
          fetchMe()
          cb?.()
          addToast('success', 'Profile updated!')
        }
      } catch (error) {}
    },
    [createEnrollment, fetchMe, addToast],
  )

  const onSignUp = useCallback(
    async (payload: ISignUpPayload) => {
      try {
        const response = await signUp(payload)
        if (response.data.success) {
          addToast('success', 'Please check provided email')
          navigate(homePath)
        }
      } catch (error) {}
    },
    [signUp, addToast, navigate],
  )

  const onSignIn = useCallback(
    async (payload: ISignInPayload) => {
      try {
        const { data } = await signIn(payload)
        const decoded: IDecodedToken = jwtDecode(data.authToken)
        setTokenInfo(decoded)
        setStorageValue(AUTH_TOKEN_KEY, data.authToken)
        setStorageValue(REFRESH_TOKEN_KEY, data.refreshToken)
        onAuthenticated(!!data.authToken)
      } catch (error) {}
    },
    [signIn, onAuthenticated],
  )

  const onReset = useCallback(
    async (payload: TResetSchema) => {
      if (!resetToken) return
      try {
        await reset({
          ...payload,
          token: resetToken,
        })
        setResetToken(undefined)
      } catch (error) {
        removeStorageValue(AUTH_TOKEN_KEY)
      }
    },
    [reset, resetToken],
  )

  const onForgot = useCallback(
    async (payload: IForgotPayload) => {
      try {
        const response = await forgot(payload)
        if (response.data.success) {
          navigate(homePath)
        }
      } catch (error) {}
    },
    [forgot, navigate],
  )

  useEffect(() => {
    if (!authenticated && !_resetToken) {
      try {
        if (authToken) {
          const decoded: IDecodedToken = jwtDecode(authToken)
          const { exp } = decoded
          setTokenInfo(decoded)
          const expired = new Date().getTime() >= exp * 1000
          onAuthenticated(!!authToken && !expired)

          if (expired && refreshToken) {
            // TODO refresh token
          }
        } else if (!publicPaths.includes(pathname)) {
          onAuthenticated(false)
        }
      } catch {
        onAuthenticated(false)
      }
    }
  }, [
    pathname,
    authToken,
    _resetToken,
    refreshToken,
    authenticated,
    onAuthenticated,
  ])

  const onUploadAvatar = useCallback(
    async (_file: File, _userId?: string) => {
      const formData = new FormData()
      formData.append('avatar', _file)
      if (_userId) {
        formData.append('userId', _userId)
      }
      const response = await uploadAvatar(formData)
      if (_userId) return
      // @ts-ignore
      setMe(prev => ({ ...prev, avatar: response.data.avatar }))
    },
    [uploadAvatar],
  )

  const onUpdateRequests = useCallback((requests: number) => {
    setRequests(requests)
  }, [])

  const onChooseRole = useCallback(
    async (role: IRole) => {
      const response = await chooseRole({
        role,
      })
      if (response.data._id) {
        setMe(response.data)
      }
    },
    [chooseRole],
  )

  // @ts-ignore
  window.signOut = onSignOut

  const value = useMemo(
    () => ({
      me,
      onReset,
      onSignIn,
      requests,
      onSignUp,
      onForgot,
      setSecret,
      onSignOut,
      tokenInfo,
      meLoading,
      resetToken,
      clientSecret,
      authenticated,
      onUploadAvatar,
      hasPaymentModal,
      onSetSubscription,
      onUpdateProfile,
      onChooseRole,
      onUpdateRequests,
      togglePaymentModal,
      createEnrollmentLoading,
      isProctor: me?.role === IRole.Proctor,
      isSchoolOwner: me?.role === IRole.AffiliateOwner,
      isAdmin: me?.role === IRole.Admin || me?.role === IRole.Manager,
    }),
    [
      me,
      onReset,
      requests,
      onSignIn,
      onForgot,
      onSignUp,
      setSecret,
      onSignOut,
      tokenInfo,
      meLoading,
      resetToken,
      clientSecret,
      authenticated,
      onUploadAvatar,
      onChooseRole,
      hasPaymentModal,
      onUpdateProfile,
      onUpdateRequests,
      onSetSubscription,
      togglePaymentModal,
      createEnrollmentLoading,
    ],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
