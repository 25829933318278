import { IconType } from '@/components/Icon/types'
import { honoraryDoctoratePath } from '@/utils'

interface ITab {
  title: string
  path: string
  icon?: IconType
  subMenu?: ITab[]
}

export const Tabs: ITab[] = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Footer Us',
    path: '/about_us',
  },
  {
    title: 'Courses We Offer',
    path: '/courses_we_offer',
  },
  {
    title: 'Student Info',
    path: '/student_nfo',
    icon: 'chevron-down',
    subMenu: [
      {
        title: 'Online Shop',
        path: '/',
      },
      {
        title: 'Study Material',
        path: '/',
      },
      {
        title: 'Events',
        path: '/',
      },
      {
        title: 'Transcript  Request',
        path: '/',
      },
      {
        title: 'Renewals',
        path: '/',
      },
    ],
  },
  {
    title: 'Ways To Give',
    path: '/ways_to_give',
  },
  {
    title: 'Make a Difference',
    path: '/make_difference',
  },
  {
    title: 'Honorary Doctorate',
    path: honoraryDoctoratePath,
  },
  {
    title: 'Contact Us',
    path: '/contact_us',
    icon: 'chevron-down',
    subMenu: [
      {
        title: 'Resources',
        path: '/',
      },
    ],
  },
]
