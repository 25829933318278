export const API_ENDPOINT = `${process.env.VITE_HTTP_PROTOCOL}://${process.env.VITE_API_ENDPOINT}`
export const WSS_ENDPOINT = `${process.env.VITE_WEBSOCKET_PROTOCOL}://${process.env.VITE_API_ENDPOINT}`

export const AUTH_TOKEN_KEY = '@a_token'
export const COURSE_ID_KEY = '@a_courseId'
export const COURSE_STATUS_KEY = '@a_status'
export const REFRESH_TOKEN_KEY = '@a_refreshToken'

export const ENV = import.meta.env
export const DATE_FORMAT = 'YYYY/MM/DD HH:mm'
