import React, { useCallback } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { NavLink, useSearchParams } from 'react-router-dom'

import { IOption, publicRoles, roles, TSignUpFormSchema } from '@/types'
import { Button, Form, Select, TextInput } from '@/components'
import { useAuth, useBrand, useHookForm } from '@/hooks'
import { signInPath, signUpFormSchema } from '@/utils'

export const SignUpPage: React.FC = () => {
  const { onSignUp } = useAuth()
  const { subDomain } = useBrand()
  const [queryParams] = useSearchParams()
  const {
    handler: {
      trigger,
      register,
      setValue,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(signUpFormSchema)

  const onSubmit: SubmitHandler<TSignUpFormSchema> = useCallback(
    data => {
      const inviterCode = queryParams.get('inviterCode')
      onSignUp({
        ...data,
        subDomain: subDomain ?? undefined,
        ...(inviterCode && { inviterCode }),
      })
    },
    [onSignUp, queryParams, subDomain],
  )
  const onSelect = useCallback(
    (_option: IOption<string>) => {
      setValue('role', roles.indexOf(_option.value))
      trigger('role')
    },
    [setValue, trigger],
  )

  return (
    <div className="form">
      <h2>Sign Up</h2>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-center">
          <div className="w-1/5">
            <TextInput
              type="text"
              label="Name"
              error={errors.name?.message}
              {...register('name', {
                onChange: () => {
                  trigger('name')
                },
              })}
            />
            <TextInput
              type="text"
              label="Email"
              error={errors.email?.message}
              {...register('email', {
                onChange: () => {
                  trigger('email')
                },
              })}
            />
            <TextInput
              type="password"
              label="Password"
              error={errors.password?.message}
              {...register('password', {
                onChange: () => {
                  trigger('password')
                },
              })}
            />
            <Select
              label="Role"
              onChange={onSelect}
              className="w-full mb-2"
              placeHolder="Choose role"
              error={errors.role?.message}
              value={roles[getValues('role')]}
              options={publicRoles.map(_v => ({ label: _v, value: _v }))}
            />
          </div>
        </div>
        <Button
          type="submit"
          className="text-white font-bold bg-red-500 cursor-pointer p-2 rounded hover:bg-red-700 my-2"
        >
          Sign up
        </Button>
      </Form>
      <p>
        Have an account?
        <NavLink to={signInPath}> Sign in</NavLink>
      </p>
    </div>
  )
}
