import React, { useCallback } from 'react'
import { SubmitHandler } from 'react-hook-form'

import { Button, TextInput, Form } from '@/components'
import { useAuth, useBrand, useHookForm } from '@/hooks'
import { forgotFormSchema } from '@/utils'
import { TForgotSchema } from '@/types'

export const ForgotPassPage: React.FC = () => {
  const { onForgot } = useAuth()
  const { subDomain } = useBrand()

  const {
    handler: {
      trigger,
      register,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(forgotFormSchema)

  const onSubmit: SubmitHandler<TForgotSchema> = useCallback(
    data => {
      onForgot({ ...data, subDomain: subDomain ?? undefined })
    },
    [onForgot, subDomain],
  )
  return (
    <div className="form">
      <h2>Forgot Password</h2>
      <p className="forgotPass-desc">
        Fill out the form below, and instructions to reset your password will be
        emailed to you:
      </p>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-center">
          <div className="w-1/5">
            <TextInput
              type="text"
              label="Email"
              error={errors.email?.message}
              {...register('email', {
                onChange: () => {
                  trigger('email')
                },
              })}
            />
          </div>
        </div>
        <Button
          type="submit"
          disabled={!getValues('email')}
          className="text-white font-bold bg-red-500 cursor-pointer p-2 rounded hover:bg-red-700"
        >
          Reset My Password
        </Button>
      </Form>
    </div>
  )
}
