import { IH5PInstance } from '@lumieducation/h5p-webcomponents'
import { AxiosError } from 'axios'

import { API_ENDPOINT, AUTH_TOKEN_KEY } from '@/constants'
import {
  IChat,
  IUser,
  IMultiChoiceAnswers,
  IQuestionSetAnswers,
  IH5PPlayerProgressOptions,
  CourseFilters,
  PaymentSchemaTypes,
} from '@/types'

import { getStorageValue } from './storage'

export const generateFilePath = (path?: Maybe<string>, isSecure = true) => {
  if (!path) return undefined
  const token = 'Bearer%20' + getStorageValue(AUTH_TOKEN_KEY)
  return `${API_ENDPOINT}${isSecure ? `/api` : ''}/${
    path.startsWith('.')
      ? path.slice(2)
      : path.startsWith('/')
        ? path.slice(1)
        : path
  }${isSecure ? `?token=${token}` : ''}`
}

export const selectUser = (chat: Partial<IChat>, user: IUser) => {
  return chat.sender?._id === user._id ? chat.receiver : chat.sender
}

export const extractErrorFromResponse = (error: AxiosError<any>) => {
  let message = error.message

  const responseError =
    error.response?.data?.message || error.response?.data?.errors
  if (
    Array.isArray(responseError) &&
    Object.values<string>(responseError[0]).length
  ) {
    message = Object.values<string>(responseError[0])?.[0]
  } else if (responseError) {
    message = responseError
  }

  if (!message) {
    message = error.message
  }

  if (error.response?.status === 401) {
    // @ts-ignore
    window?.signOut?.()
  }

  return message
}

const calculateSelections = (
  obj: IQuestionSetAnswers | IMultiChoiceAnswers,
): number => {
  if (obj && obj.answers) {
    // For H5P QuestionSet
    if (
      Array.isArray(obj.answers) &&
      obj.answers.length > 0 &&
      obj.answers[0].hasOwnProperty('answers')
    ) {
      const count = obj.answers.reduce(
        // @ts-ignore
        (count, question) => count + (question?.answers?.length || 0),
        0,
      )
      // @ts-ignore
      return count
    }

    // For H5P MultiChoice
    if (Array.isArray(obj.answers)) {
      return obj.answers.length
    }
  }

  return 0
}

export const h5pPlayerProgress = (
  h5pInstance: IH5PInstance,
  options?: IH5PPlayerProgressOptions,
) => {
  const { init, onGetProgress, onUpdateProgress } = options || {}
  // @ts-ignore
  const moduleType = h5pInstance?.libraryInfo?.machineName
  let result = 0
  let maxProgress = 0

  switch (moduleType) {
    case 'H5P.InteractiveVideo':
      // @ts-ignore
      ;(h5pInstance?.interactions || []).forEach(
        // @ts-ignore
        interaction => {
          const instance = interaction.getInstance?.()
          const { result: res, maxProgress: max } = h5pPlayerProgress(
            instance,
            {
              init: true,
            },
          )
          result += res
          maxProgress += max
        },
        0,
      )
      onGetProgress?.(maxProgress)
      onUpdateProgress?.(result ?? 0)
      return { result, maxProgress }
    case 'H5P.Essay':
      maxProgress = 1
      onGetProgress?.(maxProgress)
      // @ts-ignore
      result = h5pInstance?.isAnswered ? 1 : 0
      onUpdateProgress?.(result)
      return { result, maxProgress }

    case 'H5P.Blanks':
      if (init) {
        // @ts-ignore
        if (h5pInstance.content) {
          // @ts-ignore
          maxProgress = h5pInstance?.content?.length
          onGetProgress?.(maxProgress)
        }
      }
      // @ts-ignore
      result = h5pInstance?.answered ? h5pInstance.content.length : 0
      onUpdateProgress?.(result)
      return { result, maxProgress }

    case 'H5P.MultiChoice':
    case 'H5P.QuestionSet':
      if (init) {
        // @ts-ignore
        if (h5pInstance.getMaxScore) {
          // @ts-ignore
          maxProgress = h5pInstance.getMaxScore?.() ?? 0
          onGetProgress?.(maxProgress)
        }
      }
      // @ts-ignore
      result = calculateSelections(h5pInstance?.getCurrentState?.())
      onUpdateProgress?.(result)
      return { result, maxProgress }

    case 'H5P.SingleChoiceSet':
      // @ts-ignore
      const results = h5pInstance.results
      result = Object.keys(results || {}).reduce(
        (acc, key) => (acc += results[key] ?? 0),
        0,
      )
      // @ts-ignore
      return { result, maxProgress: h5pInstance?.choices?.length }

    case 'H5P.Text':
      return { result, maxProgress }

    case 'H5P.TrueFalse':
      if (init) {
        // @ts-ignore
        if (h5pInstance.getMaxScore) {
          // @ts-ignore
          maxProgress = h5pInstance.getMaxScore?.() ?? 0
          onGetProgress?.(maxProgress)
        }
      }
      result =
        // @ts-ignore
        h5pInstance?.getCurrentState?.()?.answer !== undefined ? 1 : 0
      onUpdateProgress?.(result)
      return { result, maxProgress }
    default:
      return { result, maxProgress }
  }
}

export const affiliateTableFilters = [
  {
    label: 'Affiliate',
    key: CourseFilters.AFFILIATE,
  },
  {
    label: 'Unity Central',
    key: CourseFilters.CENTRAL,
  },
]

export const getPaymentTypeName = (type: PaymentSchemaTypes) => {
  return type === PaymentSchemaTypes.SUBMISSION
    ? 'Doctorate'
    : type === PaymentSchemaTypes.AFFILIATE
      ? 'Affiliate'
      : type === PaymentSchemaTypes.COURSE
        ? 'Course'
        : type === PaymentSchemaTypes.QUIZ
          ? 'Quiz'
          : 'Other'
}

export const makeBarStats = (data?: { [key: string]: number }) => {
  const result = new Array<number | undefined>(12).fill(undefined)

  Object.keys(data ?? {}).forEach(key => {
    result[+key - 1] = data?.[key]
  })

  return result
}

export const getFile = (path?: string, isBackground = false) => {
  const imgPath = `${API_ENDPOINT}/${path}`
  if (!path) return ''
  if (isBackground) {
    return `url(${imgPath})`
  }
  return imgPath
}
