import React from 'react'
import { capitalize } from 'lodash'

import { FieldArray, TextInput } from '@/components'

import { ISectionProps } from '../types'

import { UploadImage } from './uploadImg'

import heroImg from '@/assets/images/template1.png'

export const EditHeroSection: React.FC<ISectionProps> = ({
  errors,
  control,
  register,
  onUpload,
  getValues,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <img src={heroImg} alt="" className="w-full object-contain" />
      <div className="flex flex-wrap w-full justify-between items-center">
        <div className="w-3/6">
          <TextInput
            type="text"
            label="Title"
            {...register('title')}
            placeholder="Enter the title"
            error={errors.title?.message}
          />
          <TextInput
            type="text"
            label="Description"
            {...register('description')}
            placeholder="Enter the description"
            error={errors.description?.message}
          />
          <TextInput
            type="text"
            label="Watch video"
            {...register('mainVideo')}
            placeholder="Enter the video url"
            error={errors.mainVideo?.message}
          />
        </div>
        <UploadImage
          maxWidth="100%"
          className="mb-4"
          onSelect={onUpload('image')}
          imagePath={getValues('image.path')}
          error={errors.image?.file?.message}
        />
      </div>
      <div className="flex gap-2">
        <FieldArray name="socials" control={control}>
          {_fields =>
            _fields.map((_field, idx) => (
              <TextInput
                type="text"
                key={_field.id}
                // @ts-ignore
                label={capitalize(_field.type)}
                {...register(`socials.${idx}.link`)}
                error={errors?.socials?.[idx]?.link?.message}
                // @ts-ignore
                placeholder={`Enter ${_field.type} url`}
              />
            ))
          }
        </FieldArray>
      </div>
    </div>
  )
}
