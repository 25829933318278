import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react'
import { Outlet } from 'react-router-dom'

import { IAffiliateBrand, IColor, ITemplate } from '@/types'
import { useBrandDetails } from '@/apis'
import { getSubdomain } from '@/utils'

interface IBrandContext {
  color?: IColor
  template?: ITemplate
  isAffiliate: boolean
  subDomain: string | null
  affiliateBrand?: IAffiliateBrand
}

export const BrandContext = createContext({} as IBrandContext)

export const BrandContextProvider: React.FC = () => {
  const [affiliateBrand, setAffiliateBrand] = useState<IAffiliateBrand>()

  const subDomain = useMemo(() => getSubdomain(), [])

  const onBrandSuccess = useCallback((_data: IAffiliateBrand) => {
    setAffiliateBrand(_data)
  }, [])

  const [getBrand, { isLoading }] = useBrandDetails(onBrandSuccess)

  useEffect(() => {
    if (!!subDomain) {
      getBrand({
        pathParams: {
          subDomain,
        },
      })
    }
  }, [getBrand, subDomain])

  const template = useMemo(
    () =>
      affiliateBrand?.templates?.find(
        _v => _v.name === affiliateBrand.defaultBrand,
      ),
    [affiliateBrand],
  )

  const value = useMemo(
    () => ({
      template,
      subDomain,
      affiliateBrand,
      color: affiliateBrand?.color,
      isAffiliate: !!subDomain && (isLoading || !!affiliateBrand),
    }),
    [template, subDomain, affiliateBrand, isLoading],
  )

  return (
    <BrandContext.Provider value={value}>
      <Outlet />
    </BrandContext.Provider>
  )
}
