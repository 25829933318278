import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { homePath, signInPath, signUpPath } from '@/utils'
import { Button } from '@/components'
import { useBrand } from '@/hooks'
import { TSocial } from '@/types'

import instagramImg from '../assets/img/icons/instagram.png'
import facebookImg from '../assets/img/icons/facebook.png'
import twitterImg from '../assets/img/icons/twitter.png'

export const DefaultTemplateFooter: React.FC = () => {
  const navigate = useNavigate()
  const { affiliateBrand, template, color } = useBrand()
  return (
    <footer>
      <div className="container">
        <div className="d-flex justify-content-center justify-content-lg-between align-items-center mb-4 flex-wrap">
          <Link to={homePath} className="footer-brand mb-4 mb-lg-0">
            {affiliateBrand?.affiliate?.name}
          </Link>
          <div className="d-flex align-items-center">
            <p className="login-text mb-0 blue">
              Have an account? <Link to={signInPath}>Login</Link>
            </p>
            <Button
              type="button"
              className="btn custom-btn extra gold"
              onClick={() => navigate(signUpPath)}
              style={{
                backgroundColor: color?.activeButton,
              }}
            >
              Get Started
            </Button>
          </div>
        </div>
        <hr />
        <div className="footer-social my-4 justify-content-center justify-content-lg-start">
          <p className="follow-us mb-0">Follow us : </p>
          {template?.socials?.map((social, idx) => (
            <Link key={idx} to={social.link} className="follow-link">
              <img
                src={
                  social.type === TSocial.FACEBOOK
                    ? facebookImg
                    : social.type === TSocial.TWITTER
                      ? twitterImg
                      : instagramImg
                }
                alt=""
              />
            </Link>
          ))}
        </div>
        <div className="footer-copyright justify-content-center justify-content-lg-between">
          <div className="copyright">© UnityLearn All Right Reserved.</div>
          <div className="privacy">
            <Link to="#" className="policy">
              Privacy Policy
            </Link>
            <Link to="#" className="policy">
              Terms of Service
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}
