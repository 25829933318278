import React from 'react'
import styles from 'bootstrap/scss/bootstrap.scss?inline'

import { Lazy } from '@/components'

import {
  RewardSection,
  AdvanceSection,
  AdvantagesSection,
  DefaultHeroSection,
} from './components'

import './assets/scss/styles.scss'

export const DefaultTemplate: React.FC = () => {
  return (
    <React.Fragment>
      <style>{styles}</style>
      <DefaultHeroSection />
      <Lazy>
        <AdvantagesSection />
      </Lazy>
      <Lazy>
        <AdvanceSection />
      </Lazy>
      <Lazy>
        <RewardSection />
      </Lazy>
    </React.Fragment>
  )
}

export * from './components'
