import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react'
import { Outlet } from 'react-router-dom'

import {
  IPageMeta,
  IPayment,
  IPaymentHistoryResponse,
  IPaymentStat,
  IPaymentStatsResponse,
} from '@/types'
import { useGetPaymentHistory, usePaymentStats } from '@/apis'

interface IPaymentContext {
  totalItems: number
  history: IPayment[]
  pageMeta: IPageMeta
  stats: IPaymentStat[]
  statsLoading: boolean
  historyLoading: boolean
  onPageMetaChange: (_page: number, _pageSize: number) => void
}

const defaultPageMeta = {
  page: 1,
  pageSize: 10,
}

export const PaymentContext = createContext<IPaymentContext>({
  stats: [],
  history: [],
  totalItems: 0,
  statsLoading: true,
  historyLoading: true,
  pageMeta: defaultPageMeta,
  onPageMetaChange: () => null,
})

export const PaymentContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [history, setHistory] = useState<IPayment[]>([])
  const [stats, setStats] = useState<IPaymentStat[]>([])
  const [totalItems, setTotalItems] = useState<number>(0)
  const [pageMeta, setPageMeta] = useState<IPageMeta>(defaultPageMeta)

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  const onHistorySuccess = useCallback((res: IPaymentHistoryResponse) => {
    setHistory(res.data.history)
    setTotalItems(res.data.totalItems)
  }, [])

  const onStatsSuccess = useCallback((res: IPaymentStatsResponse) => {
    setStats(res.data)
  }, [])

  const [getPaymentHistory, { isLoading: historyLoading }] =
    useGetPaymentHistory(onHistorySuccess)

  const [getStats, { isLoading: statsLoading }] =
    usePaymentStats(onStatsSuccess)

  useEffect(() => {
    getPaymentHistory()
  }, [getPaymentHistory])

  useEffect(() => {
    getStats()
  }, [getStats])

  const value = useMemo(
    () => ({
      stats,
      history,
      pageMeta,
      totalItems,
      statsLoading,
      historyLoading,
      onPageMetaChange,
    }),
    [
      stats,
      history,
      pageMeta,
      totalItems,
      statsLoading,
      historyLoading,
      onPageMetaChange,
    ],
  )

  return (
    <PaymentContext.Provider value={value}>
      {children ?? <Outlet />}
    </PaymentContext.Provider>
  )
}
