import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAuth, useReferral } from '@/hooks'
import QRCode from 'react-qr-code'
import { Link } from 'react-router-dom'
import { DataTable, WithPaginator } from '@/components'
import moment from 'moment'
import { DATE_FORMAT } from '@/constants'
import { IPageMeta } from '@/types'

const TABS = [
  {
    key: 'referral',
    name: 'Referral',
    link: '/referral',
  },
  {
    key: 'student-affiliate',
    name: 'Student affiliate',
    link: '/referral/student-affiliate',
  },
]
export const ReferralPage: React.FC<{ tab?: string }> = ({
  tab = 'referral',
}) => {
  const { me } = useAuth()
  const { invitees, onGetInvitees } = useReferral()
  const [isCopiedFinished, setIsCopiedFinished] = useState(false)
  const inviteLink = `${window.location.host}/sign-up?inviterCode=${me?.inviteCode || ''}`
  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    page: 1,
    pageSize: 10,
  })

  useEffect(() => {
    if (tab === 'student-affiliate') {
      onGetInvitees()
    }
  }, [onGetInvitees, tab])

  const copyReferralLink = useCallback(() => {
    navigator.clipboard.writeText(inviteLink).then(() => {
      setIsCopiedFinished(true)
      setTimeout(() => {
        setIsCopiedFinished(false)
      }, 2000)
    })
  }, [])

  const headers = useMemo(() => {
    const _headers = [
      { label: '', key: '' },
      { label: 'Name', key: 'name' },
      { label: 'Created at', key: 'createdAt' },
    ]

    return _headers
  }, [])

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  const pageData = useMemo(() => {
    const firstPageIndex = (pageMeta.page - 1) * (pageMeta.pageSize || 10)
    const lastPageIndex = firstPageIndex + (pageMeta.pageSize || 10)
    return invitees.slice(firstPageIndex, lastPageIndex)
  }, [invitees, pageMeta.page, pageMeta.pageSize])

  return (
    <div className="container mx-auto pb-8 pt-2">
      <div className="flex gap-4 mb-4 border-b border-gray-200">
        {TABS.map((item, index) => (
          <Link
            key={index}
            className={`text-xl font-semibold ${item.key === tab && 'border-b border-gray-800'}`}
            to={item.link}
          >
            {item.name}
          </Link>
        ))}
      </div>
      {tab === 'student-affiliate' ? (
        <WithPaginator
          data={pageData}
          initialPageSize={10}
          totalItems={invitees.length}
          onPageMetaChange={onPageMetaChange}
        >
          {pagedData => (
            <DataTable data={pagedData} headers={headers}>
              {data => {
                return data.map((item, idx) => (
                  <tr key={idx} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b text-start">{idx + 1}</td>
                    <td className="py-2 px-4 border-b text-start">
                      {item.name}
                    </td>
                    <td className="py-2 px-4 border-b text-start">
                      {moment(item.createdAt).format(DATE_FORMAT)}
                    </td>
                  </tr>
                ))
              }}
            </DataTable>
          )}
        </WithPaginator>
      ) : (
        <div className="w-full flex flex-col items-center">
          <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-3">
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={inviteLink}
              viewBox={`0 0 256 256`}
            />
          </div>
          <div className="flex items-center max-w-sm">
            <span className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg dark:bg-gray-600 dark:text-white dark:border-gray-600">
              Referral Link
            </span>
            <div className="relative w-full">
              <input
                type="text"
                aria-describedby="helper-text-explanation"
                className="bg-gray-50 border border-e-0 border-gray-300 text-gray-500 dark:text-gray-400 text-sm border-s-0 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={inviteLink}
                readOnly
                disabled
              />
            </div>
            <button
              disabled={isCopiedFinished}
              onClick={copyReferralLink}
              className="flex-shrink-0 z-10 inline-flex items-center py-3 px-4 text-sm font-medium text-center text-white bg-green-500 hover:bg-green-700 rounded-e-lg focus:outline-none dark:bg-green-600 dark:hover:bg-green-700 border border-green-500 dark:border-green-600 hover:border-green-700 dark:hover:border-green-700"
              type="button"
            >
              {isCopiedFinished ? (
                <span className="inline-flex items-center">
                  <svg
                    className="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5.917 5.724 10.5 15 1.5"
                    />
                  </svg>
                </span>
              ) : (
                <span>
                  <svg
                    className="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 18 20"
                  >
                    <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                  </svg>
                </span>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
