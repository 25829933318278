import React from 'react'

import { Button } from '@/components'

import { FlexibleData } from '../mock/bannerAndFlexibleData'

export const Flexible: React.FC = () => {
  return (
    <section className="banner">
      <div className="banner-img" style={{ height: '563px' }}>
        <img src={FlexibleData.img} alt={FlexibleData.title} />
      </div>

      <div className="banner-desc">
        <h1 style={{ fontSize: '50px' }}>{FlexibleData.title}</h1>
        <p>{FlexibleData.desc}</p>
        <div className="button-style">
          <Button type="button">Courses</Button>
        </div>
      </div>
    </section>
  )
}
