import HandImg from '@/assets/images/hand-help-section.webp'
import HeartImg from '@/assets/images/heart-help-section.webp'
import GramophoneImg from '@/assets/images/gramafon-help-section.webp'

export const HelpData = [
  {
    img: HandImg,
    title: 'Registered',
    desc: (
      <p>
        Our high school diploma program is registered in the states of{' '}
        <span>Maryland</span>, and <span>New Jersey.</span>{' '}
      </p>
    ),
  },
  {
    img: HeartImg,
    title: 'Become a Volunteer',
    desc: (
      <p>
        Do you have some extra time to spare? If so, we can always use
        volunteers to participate in our outreach programs and community events.
        "
      </p>
    ),
  },

  {
    img: GramophoneImg,
    title: 'Spread the Word',
    desc: (
      <p>
        Help us spread the word and educate others about our foundation so we
        can make a bigger difference.
      </p>
    ),
  },
]
