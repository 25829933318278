import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { getFile, homePath, signInPath, signUpPath } from '@/utils'
import { Button } from '@/components'
import { useBrand } from '@/hooks'

export const DefaultTemplateHeader: React.FC = () => {
  const navigate = useNavigate()
  const { affiliateBrand } = useBrand()
  return (
    <header>
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark p-0">
          <Link className="navbar-brand d-flex gap-2" to={homePath}>
            <img alt="" className="h-9" src={getFile(affiliateBrand?.logo)} />
            {affiliateBrand?.affiliate.name}
          </Link>
          <Button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </Button>
          <div id="navbarSupportedContent" className="navbar-collapse">
            <ul className="navbar-nav ml-auto d-flex align-items-center">
              <p className="login-text mb-0">
                Have an account? <Link to={signInPath}>Login</Link>
              </p>
              <Button
                type="button"
                className="btn custom-btn extra"
                onClick={() => navigate(signUpPath)}
              >
                Get Started
              </Button>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  )
}
