import { ITab, Templates } from '@/types'

import Template1Img from '@/assets/images/template1.png'

export enum AffiliateTabs {
  AFFILIATE,
  BRAND,
  TEMPLATE,
}

export const myAffiliateTabs: ITab<AffiliateTabs>[] = [
  {
    label: 'School',
    key: AffiliateTabs.AFFILIATE,
  },
  {
    label: 'Brand',
    key: AffiliateTabs.BRAND,
  },
  {
    label: 'Template',
    key: AffiliateTabs.TEMPLATE,
  },
]

export const templates = [
  {
    image: Template1Img,
    label: 'Default Template',
    value: Templates.DEFAULT_TEMPLATE,
  },
]
