import React from 'react'

import { Button } from '@/components'

import OurMissionImg from '@/assets/images/our-mission-img.webp'

export const OurMission: React.FC = () => {
  return (
    <section className="our-mission container">
      <div className="our-mission-img">
        <img src={OurMissionImg} alt="our images" />
      </div>
      <div className="our-mission-info">
        <h2>Our Mission</h2>
        <p>
          Our mission is to Train Champions. We’re committed to championing you
          as you study to go further in your field, become a leader in your
          industry, or start a new career.
        </p>
        <div className="button-style">
          <Button type="button">Courses</Button>
        </div>
      </div>
    </section>
  )
}
