import React, { useCallback } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { NavLink } from 'react-router-dom'

import { forgotPath, loginFormSchema, signUpPath } from '@/utils'
import { useAuth, useHookForm, useBrand } from '@/hooks'
import { Button, Form, TextInput } from '@/components'
import { TLoginSchema } from '@/types'

export const SignInPage: React.FC = () => {
  const { onSignIn } = useAuth()
  const { affiliateBrand, subDomain } = useBrand()

  const {
    handler: {
      trigger,
      register,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(loginFormSchema)

  const onSubmit: SubmitHandler<TLoginSchema> = useCallback(
    data => {
      onSignIn({ ...data, subDomain: subDomain ?? undefined })
    },
    [onSignIn, subDomain],
  )

  return (
    <div className="form">
      <h2>Login</h2>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-center">
          <div className="w-1/5">
            <TextInput
              type="text"
              label="Email"
              error={errors.email?.message}
              {...register('email', {
                onChange: () => {
                  trigger('email')
                },
              })}
            />
            <TextInput
              type="password"
              label="Password"
              error={errors.password?.message}
              {...register('password', {
                onChange: () => {
                  trigger('password')
                },
              })}
            />
          </div>
        </div>
        <Button
          type="submit"
          className="text-white font-bold bg-red-500 cursor-pointer p-2 rounded hover:bg-red-700"
        >
          Login
        </Button>
      </Form>
      {(affiliateBrand || !subDomain) && (
        <>
          <NavLink to={forgotPath}>Forgot Password?</NavLink>
          <p>
            Don't have an account?
            <NavLink to={signUpPath}> Sign up</NavLink>
          </p>
        </>
      )}
    </div>
  )
}
