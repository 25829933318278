import React, { createContext, useCallback, useMemo, useState } from 'react'

import { useAcceptDocument, useGetDiploma, useGetDocuments } from '@/apis'
import { ITask, IDocumentResponse } from '@/types'

interface IDocumentsContext {
  documents: ITask[]

  documentsLoading: boolean
  onGetDocuments: () => void
  onAccept: (_documentId: string) => void
  onDownloadDocument: (_documentId: string) => void
}

export const DocumentsContext = createContext<IDocumentsContext>({
  documents: [],
  documentsLoading: false,
  onAccept: () => undefined,
  onGetDocuments: () => undefined,
  onDownloadDocument: () => undefined,
})

export const DocumentsContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [documents, setDocuments] = useState<ITask[]>([])

  const documentsSuccess = useCallback((_data: IDocumentResponse) => {
    setDocuments(_data.data)
  }, [])

  const onGetDiploma = useCallback((data: any) => {
    const blob = new Blob([data])

    // Create a Blob URL
    const blobUrl = URL.createObjectURL(blob)

    // Create a link element to trigger the download
    const link = document.createElement('a')
    link.href = blobUrl
    link.download = 'diploma.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [])

  const [getDocuments, { isLoading: documentsLoading }] =
    useGetDocuments(documentsSuccess)
  const [getDiploma] = useGetDiploma(onGetDiploma)
  const { mutateAsync: acceptDocument } = useAcceptDocument()

  const onGetDocuments = useCallback(() => {
    getDocuments()
  }, [getDocuments])

  const onAccept = useCallback(
    async (documentId: string) => {
      await acceptDocument({
        documentId,
      })
      onGetDocuments()
    },
    [acceptDocument, onGetDocuments],
  )

  const onDownloadDocument = useCallback(
    (documentId: string) => {
      getDiploma({
        queryParams: [
          {
            documentId,
          },
        ],
      })
    },
    [getDiploma],
  )

  const value = useMemo(
    () => ({
      onAccept,
      documents,
      onGetDocuments,
      documentsLoading,
      onDownloadDocument,
    }),
    [documents, documentsLoading, onAccept, onDownloadDocument, onGetDocuments],
  )

  return (
    <DocumentsContext.Provider value={value}>
      {children}
    </DocumentsContext.Provider>
  )
}
