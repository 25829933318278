import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { signInPath, signUpPath, generateFilePath } from '@/utils'
import { Popover } from '@/components'
import { useAuth } from '@/hooks'

import avatar from '@/assets/images/client-avatar.png'

export const options = (isAuthenticated = false) => [
  ...(isAuthenticated
    ? []
    : [
        {
          label: 'Sign In',
          path: signInPath,
        },
        {
          label: 'Sign Up',
          path: signUpPath,
        },
      ]),
]

export const Profile: React.FC = () => {
  const { onSignOut, authenticated, me, isAdmin } = useAuth()

  const studentOptions = useMemo(() => options(authenticated), [authenticated])
  return (
    <div className="profile">
      <Popover
        placement="bottom-end"
        button={<img src={generateFilePath(me?.avatar) || avatar} alt="" />}
      >
        {onClose => (
          <div className="bg-drop">
            <ul>
              {[...(isAdmin ? [] : studentOptions)].map((option, idx) => (
                <li key={idx}>
                  <Link onClick={onClose} to={option.path}>
                    {option.label}
                  </Link>
                </li>
              ))}
              {authenticated && (
                <li onClick={onSignOut}>
                  <p>Log out</p>
                </li>
              )}
            </ul>
          </div>
        )}
      </Popover>
    </div>
  )
}
