import { SectionTypes, Templates } from '@/types'

export const sectionTypesData: Record<Templates, SectionTypes[]> = {
  default_template: [
    SectionTypes.ADVANTAGES,
    SectionTypes.ADVANCE,
    SectionTypes.REWARD,
  ],
  template_1: [],
}
