import React from 'react'

export const HoursContent: React.FC = () => {
  return (
    <div className="hours-details">
      <p>
        <span>Monday - Friday:</span> 9:00am - 5:00pm
      </p>
      <p>
        <span>Saturday - Sunday:</span> Closed
      </p>
    </div>
  )
}
