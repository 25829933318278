import React, { useCallback, useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import {
  homePath,
  testsPath,
  forgotPath,
  signInPath,
  signUpPath,
  manageCoursePath,
  resetPasswordPath,
  coursePath,
  profilePath,
  referralPath,
  referralStudentAffiliatePath,
  supportPath,
  createAffiliateFormPath,
  affiliatePricingPath,
  myCoursePath,
  usersPath,
  documentsPath,
  feedbackPath,
  newPath,
  editPath,
  previewPath,
  honoraryDoctoratePath,
  honoraryDoctoralListPath,
  myTestsPath,
  consultationsPath,
  affiliatesPath,
  myAffiliatePath,
  editTemplatePath,
  rolesPath,
  proctorTasksPath,
  paymentHistoryPath,
  proctorProfilePath,
  proctorCalendarPath,
  proctorTasksHistoryPath,
  manageQuizPath,
} from '@/utils'
import {
  HomePage,
  SignInPage,
  SignUpPage,
  ResetWrapper,
  ResetPassPage,
  ForgotPassPage,
  ManageCoursePage,
  QuizPage,
  ConsultationsPage,
  AffiliatesPage,
  CreateAffiliatePage,
  MyAffiliatePage,
  EditTemplatePage,
  ProfilePage,
  ReferralPage,
  SupportPage,
  AffiliatePricingPage,
  CreateAffiliateFormPage,
  CoursesPage,
  UsersPage,
  DocumentsPage,
  FeedbacksPage,
  CreateCoursePage,
  CourseDetailsPage,
  CoursePreviewPage,
  DoctoralListPage,
  DoctorateSubmissionPage,
  CreateQuizPage,
  RolesPage,
  EditRolePage,
  PaymentPage,
  ProctorTasksPage,
  ProctorHistoryPage,
  ProctorProfilePage,
  ProctorCalendarPage,
  MyQuizPage,
  MyCoursesPage,
  QuizDetailsPage,
  ManageQuizPage,
  EditUserPage,
  AffiliatePreviewPage,
} from '@/pages'
import { AppLayout, AuthWrapper, MainLayout, PermissionWrapper } from '@/layout'
import {
  AffiliateProvider,
  BrandContextProvider,
  ReferralContextProvider,
  RolesContextProvider,
  UsersProvider,
} from '@/context'

export const AppRoutes: React.FC = () => {
  const { pathname } = useLocation()

  const onError = useCallback((e: Error) => {
    console.log({ appError: e })
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <ErrorBoundary onError={onError} fallback={<div />}>
      <Routes>
        <Route element={<BrandContextProvider />}>
          <Route path={homePath} element={<HomePage />} />
          <Route element={<AppLayout />}>
            <Route path={signInPath} element={<SignInPage />} />
            <Route path={signUpPath} element={<SignUpPage />} />
            <Route path={forgotPath} element={<ForgotPassPage />} />
            <Route element={<ResetWrapper />}>
              <Route path={resetPasswordPath} element={<ResetPassPage />} />
            </Route>
            <Route
              path={honoraryDoctoratePath}
              element={<DoctorateSubmissionPage />}
            />
          </Route>
          <Route
            path={createAffiliateFormPath}
            element={<CreateAffiliateFormPage />}
          />
          <Route element={<MainLayout />}>
            <Route element={<AuthWrapper />}>
              <Route element={<PermissionWrapper />}>
                {/* Admin course management */}
                <Route path={manageCoursePath}>
                  <Route index element={<ManageCoursePage />} />
                  <Route path={newPath} element={<CreateCoursePage />} />
                  <Route path={editPath} element={<CourseDetailsPage />} />
                  <Route path={previewPath} element={<CoursePreviewPage />} />
                </Route>
                {/* Admin Quiz management */}
                <Route path={manageQuizPath}>
                  <Route index element={<ManageQuizPage />} />
                  <Route path={newPath} element={<CreateQuizPage />} />
                  <Route path={editPath} element={<QuizDetailsPage />} />
                  <Route path={previewPath} element={<CoursePreviewPage />} />
                </Route>

                <Route path={profilePath} element={<ProfilePage />} />
                <Route path={referralPath} element={<ReferralPage />} />
                <Route path={documentsPath} element={<DocumentsPage />} />
                <Route element={<ReferralContextProvider />}>
                  <Route
                    path={referralStudentAffiliatePath}
                    element={<ReferralPage tab="student-affiliate" />}
                  />
                </Route>
                <Route path={coursePath} element={<CoursesPage />} />
                <Route element={<UsersProvider />}>
                  <Route path={usersPath}>
                    <Route index element={<UsersPage />} />
                    <Route path={editPath} element={<EditUserPage />} />
                  </Route>
                </Route>
                <Route path={testsPath}>
                  <Route index element={<QuizPage />} />
                  <Route path={newPath} element={<CreateQuizPage />} />
                </Route>
                <Route path={myTestsPath} element={<MyQuizPage />} />
                <Route path={myCoursePath} element={<MyCoursesPage />} />
                <Route path={supportPath} element={<SupportPage />} />
                <Route
                  path={affiliatePricingPath}
                  element={<AffiliatePricingPage />}
                />
                <Route
                  path={consultationsPath}
                  element={<ConsultationsPage />}
                />
                <Route path={rolesPath} element={<RolesContextProvider />}>
                  <Route index element={<RolesPage />} />
                  <Route path={editPath} element={<EditRolePage />} />
                </Route>
                <Route element={<AffiliateProvider />}>
                  <Route path={affiliatesPath}>
                    <Route index element={<AffiliatesPage />} />
                    <Route path={newPath} element={<CreateAffiliatePage />} />
                    <Route
                      path={previewPath}
                      element={<AffiliatePreviewPage />}
                    />
                  </Route>
                </Route>
                <Route path={myAffiliatePath}>
                  <Route index element={<MyAffiliatePage />} />
                  <Route
                    path={editTemplatePath}
                    element={<EditTemplatePage />}
                  />
                </Route>
                <Route path={feedbackPath} element={<FeedbacksPage />} />
                <Route
                  element={<DoctoralListPage />}
                  path={honoraryDoctoralListPath}
                />
                <Route element={<PaymentPage />} path={paymentHistoryPath} />
                {/* Proctor pages */}
                <Route
                  path={proctorProfilePath}
                  element={<ProctorProfilePage />}
                />
                <Route
                  path={proctorCalendarPath}
                  element={<ProctorCalendarPage />}
                />
                <Route path={proctorTasksPath} element={<ProctorTasksPage />} />
                <Route
                  path={proctorTasksHistoryPath}
                  element={<ProctorHistoryPage />}
                />
                <Route path="*" element={<Navigate to={homePath} />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<Navigate to={homePath} />} />
        </Route>
      </Routes>
    </ErrorBoundary>
  )
}
