import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import {
  CourseFilters,
  IPageMeta,
  IPlan,
  ISinglePlanResponse,
  IStartedProgress,
} from '@/types'
import { DataTable, Tabs, WithPaginator } from '@/components'
import { affiliateTableFilters } from '@/utils'
import { useAuth, useQuiz } from '@/hooks'
import { useGetQuizById, useStartQuiz } from '@/apis'
import { ENV } from '@/constants'

import { QuizRow } from './components'

import { CheckoutForm, PlanWidget } from '../shared'

const stripePromise = loadStripe(ENV.VITE_STRIPE_PUB_KEY)

export const QuizPage: React.FC = () => {
  const { isAdmin, isSchoolOwner, isProctor } = useAuth()
  const [activeFilter, setFilter] = useState<CourseFilters>(
    CourseFilters.AFFILIATE,
  )
  const { quizzes, onGetTests } = useQuiz()
  const [tempTest, setTempTest] = useState<IPlan>()
  const [clientSecret, setClientSecret] = useState<string>()
  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    page: 1,
    pageSize: 10,
  })
  const [selectedTest, setSelectedTest] = useState<IStartedProgress>()

  const onQuizSuccess = useCallback((data: ISinglePlanResponse) => {
    setSelectedTest(data.data)
  }, [])

  const { mutateAsync: startQuiz } = useStartQuiz()
  const [getQuizById] = useGetQuizById(onQuizSuccess)

  useEffect(() => {
    onGetTests()
  }, [onGetTests])

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  const onStartQuiz = useCallback(
    async (test?: IPlan) => {
      if (test && !isAdmin && !isSchoolOwner && !isProctor) {
        const response = await startQuiz({
          id: test?._id,
        })
        if (response.data.clientSecret) {
          setClientSecret(response.data.clientSecret)
        } else if (response.data.data) {
          setSelectedTest(response.data.data)
        }
      }
    },
    [startQuiz, isAdmin, isSchoolOwner, isProctor],
  )

  const onSelectTest = useCallback(
    (test?: IPlan, isAdmin?: boolean) => () => {
      if (!isAdmin) {
        onStartQuiz(test)
        setTempTest(test)
      }
    },
    [onStartQuiz],
  )

  const _tests = useMemo(
    () =>
      isAdmin
        ? quizzes
        : quizzes.filter(_v =>
            activeFilter === CourseFilters.AFFILIATE
              ? _v.affiliate
              : !_v.affiliate,
          ),
    [activeFilter, isAdmin, quizzes],
  )

  const pageData = useMemo(() => {
    const firstPageIndex = (pageMeta.page - 1) * (pageMeta.pageSize || 10)
    const lastPageIndex = firstPageIndex + (pageMeta.pageSize || 10)
    return _tests.slice(firstPageIndex, lastPageIndex)
  }, [_tests, pageMeta.page, pageMeta.pageSize])

  const headers = useMemo(() => {
    const _headers = [
      { label: 'ID', key: 'id' },
      { label: 'Title', key: 'title' },
      { label: 'Description', key: 'desc' },
      { label: 'Price', key: 'price' },
      { label: 'Created', key: 'createdAt' },
    ]
    return _headers
  }, [])

  const onPaymentSuccess = useCallback(async () => {
    setClientSecret(undefined)
    if (!tempTest?._id) return
    getQuizById({
      pathParams: {
        id: tempTest._id,
      },
    })
    setTempTest(undefined)
  }, [getQuizById, tempTest?._id])

  const onClose = useCallback(() => {
    setSelectedTest(undefined)
  }, [])

  const onClosePaymentForm = useCallback(() => {
    setClientSecret(undefined)
    setTempTest(undefined)
  }, [])

  if (!!selectedTest && !isProctor) {
    return (
      <PlanWidget
        onClose={onClose}
        plan={selectedTest}
        onRefresh={onGetTests}
        isAdmin={isAdmin || isSchoolOwner}
      />
    )
  }

  return (
    <div className="p-4 w-full">
      {!!clientSecret && (
        <Elements stripe={stripePromise}>
          <CheckoutForm
            onClose={onClosePaymentForm}
            clientSecret={clientSecret}
            price={tempTest?.price || 0}
            onSuccess={onPaymentSuccess}
          />
        </Elements>
      )}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-3xl font-semibold mb-4">Tests</h2>
      </div>
      <WithPaginator
        data={pageData}
        initialPageSize={10}
        totalItems={_tests.length}
        onPageMetaChange={onPageMetaChange}
      >
        {pagedData => (
          <>
            {isSchoolOwner && (
              <Tabs
                onChange={setFilter}
                activeTab={activeFilter}
                tabs={affiliateTableFilters}
              />
            )}
            <DataTable data={pagedData} headers={headers}>
              {data => {
                return data.map((item, idx) => (
                  <QuizRow {...item} key={idx} onSelectTest={onSelectTest} />
                ))
              }}
            </DataTable>
          </>
        )}
      </WithPaginator>
    </div>
  )
}
