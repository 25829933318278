import whatWeDoImg1 from '@/assets/images/what-we-do-img1.webp'
import whatWeDoImg2 from '@/assets/images/what-we-do-img2.webp'

export const WhatWeDoData = [
  {
    img: whatWeDoImg1,
    title: 'High SchoolPricing Diplomas',
  },
  {
    img: whatWeDoImg2,
    title: 'Chaplaincy Certification',
  },
]
