import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import { Icon } from '@/components'

import { Tabs } from '../constants'
import { Profile } from '@/layout/MainLayout/components'

interface IMenuProps {
  show?: boolean
  hideProfile?: boolean
  setShow?: (_show: boolean) => void
}

export const Menu: React.FC<IMenuProps> = ({ show, hideProfile = false }) => {
  const [popoverVisible, setPopoverVisible] = useState<number | null>(null)

  const handlePopoverToggle = (index: number) => {
    if (popoverVisible === index) {
      setPopoverVisible(null)
    } else {
      setPopoverVisible(index)
    }
  }

  return (
    <>
      <nav>
        <ul
          className={classNames('', {
            'show-menu': show,
            'hide-menu': !show,
          })}
        >
          {Tabs.map((tab, index) => {
            return (
              <li
                key={index}
                onMouseEnter={() => handlePopoverToggle(index)}
                onMouseLeave={() => handlePopoverToggle(index)}
              >
                <NavLink to={tab.path}>{tab.title}</NavLink>
                {tab?.icon && <Icon name={tab.icon} />}
                {popoverVisible === index && tab.subMenu && (
                  <ul className="popover">
                    {tab.subMenu.map((subItem, subIndex) => {
                      return (
                        <li key={subIndex}>
                          <NavLink to={subItem.path} className="sub-item">
                            {subItem.title}
                          </NavLink>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </li>
            )
          })}
          {!hideProfile && <Profile />}
        </ul>
      </nav>
    </>
  )
}
