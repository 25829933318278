export const review = [
  {
    review:
      // eslint-disable-next-line max-len
      "I've had the honor and pleasure of volunteering at several of their events. The entire organization consists of honest, caring and sincere individuals who are looking to make a positive impact.",
    reviewer: 'Neal M.',
  },
  {
    review:
      'I just wanted to say thank you for all of your help and support. Your organization has been a blessing to my family and the community we live in.',
    reviewer: 'Samantha P.',
  },
]
