import React from 'react'

import { Button } from '@/components'

import { WhatWeDoData } from '../mock/whatWeDoData'

interface WhatWeDoItem {
  img: string
  title: string
}

export const WhatWeDo: React.FC = () => {
  return (
    <section className="what-we-do  ">
      <div className="container">
        <h2>What We Do</h2>

        <div className="what-we-do-info">
          {WhatWeDoData.map((item: WhatWeDoItem, index: number) => {
            return (
              <div className="info-item" key={index}>
                <div className="info-img">
                  <img src={item.img} alt={item.title} />
                </div>
                <p>{item.title}</p>
              </div>
            )
          })}
        </div>
        <div className="button-style">
          <Button type="button">Enroll Now</Button>
        </div>
      </div>
    </section>
  )
}
