import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { CourseFilters, IPageMeta, IStartedProgress } from '@/types'
import { DataTable, Tabs, WithPaginator } from '@/components'
import { affiliateTableFilters } from '@/utils'
import { useAuth, useQuiz } from '@/hooks'
import { useStartQuiz } from '@/apis'
import { ENV } from '@/constants'

import { CheckoutForm, PlanWidget } from '@/pages/shared'

import { headers } from '../constants'
import { QuizRow } from './components'

const stripePromise = loadStripe(ENV.VITE_STRIPE_PUB_KEY)

export const MyQuizPage: React.FC = () => {
  const { isAdmin, isSchoolOwner, isProctor } = useAuth()
  const [activeFilter, setFilter] = useState<CourseFilters>(
    CourseFilters.AFFILIATE,
  )
  const { myTests, onGetTests } = useQuiz()
  const [clientSecret, setClientSecret] = useState<string>()
  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    page: 1,
    pageSize: 10,
  })
  const [tempTest, setTempTest] = useState<IStartedProgress>()
  const [selectedTest, setSelectedTest] = useState<IStartedProgress>()

  const { mutateAsync: startQuiz } = useStartQuiz()

  useEffect(() => {
    onGetTests()
  }, [onGetTests])

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  const onStartQuiz = useCallback(
    async (test?: IStartedProgress) => {
      if (test && !isAdmin && !isSchoolOwner && !isProctor) {
        const response = await startQuiz({
          id: test.item._id,
        })
        if (response.data.clientSecret) {
          setClientSecret(response.data.clientSecret)
        } else if (response.data.data || test) {
          setSelectedTest(response.data.data || test)
        }
      } else if (isProctor) {
        setSelectedTest(test)
      }
    },
    [startQuiz, isAdmin, isSchoolOwner, isProctor],
  )

  const onSelectTest = useCallback(
    (test: IStartedProgress) => () => {
      onStartQuiz(test)
      setTempTest(test)
    },
    [onStartQuiz],
  )

  const pageData = useMemo(() => {
    const firstPageIndex = (pageMeta.page - 1) * (pageMeta.pageSize || 10)
    const lastPageIndex = firstPageIndex + (pageMeta.pageSize || 10)
    return myTests.slice(firstPageIndex, lastPageIndex)
  }, [myTests, pageMeta.page, pageMeta.pageSize])

  const onPaymentSuccess = useCallback(async () => {
    setClientSecret(undefined)
    if (!tempTest?.item._id) return
    setSelectedTest(tempTest)
    setTempTest(undefined)
  }, [tempTest])

  const onClose = useCallback(() => {
    setSelectedTest(undefined)
  }, [])

  const onClosePaymentForm = useCallback(() => {
    setClientSecret(undefined)
    setTempTest(undefined)
  }, [])

  if (!!selectedTest && !isProctor) {
    return (
      <PlanWidget
        onClose={onClose}
        plan={selectedTest}
        isAdmin={false}
        onRefresh={onGetTests}
      />
    )
  }

  return (
    <div className="p-4 w-full">
      {!!clientSecret && (
        <Elements stripe={stripePromise}>
          <CheckoutForm
            onClose={onClosePaymentForm}
            clientSecret={clientSecret}
            onSuccess={onPaymentSuccess}
            price={tempTest?.item?.price || 0}
          />
        </Elements>
      )}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-3xl font-semibold mb-4">My Tests</h2>
      </div>
      <WithPaginator
        data={pageData}
        initialPageSize={10}
        totalItems={myTests.length}
        onPageMetaChange={onPageMetaChange}
      >
        {pagedData => (
          <>
            {isSchoolOwner && (
              <Tabs
                onChange={setFilter}
                activeTab={activeFilter}
                tabs={affiliateTableFilters}
              />
            )}
            <DataTable data={pagedData} headers={headers}>
              {data => {
                return data.map((item, idx) => (
                  <QuizRow {...item} key={idx} startQuiz={onSelectTest(item)} />
                ))
              }}
            </DataTable>
          </>
        )}
      </WithPaginator>
    </div>
  )
}
