import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'

import { Button, DataTable, WithPaginator } from '@/components'
import { DocumentsContextProvider } from '@/context'
import { useAuth, useDocuments } from '@/hooks'
import { generateFilePath } from '@/utils'
import { DATE_FORMAT } from '@/constants'
import { IPageMeta } from '@/types'

const DocumentsView: React.FC = () => {
  const { me, isAdmin, isSchoolOwner } = useAuth()
  const { onAccept, documents, onGetDocuments, onDownloadDocument } =
    useDocuments()

  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    page: 1,
    pageSize: 10,
  })

  useEffect(() => {
    onGetDocuments()
  }, [onGetDocuments])

  const openFile = useCallback(
    (filePath?: string) => () => {
      if (!filePath) return
      window.open(generateFilePath(filePath) as string, '__blank')
    },
    [],
  )

  const isStudent = useMemo(
    () => !!me && !isAdmin && !isSchoolOwner,
    [isAdmin, me, isSchoolOwner],
  )

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  const pageData = useMemo(() => {
    const firstPageIndex = (pageMeta.page - 1) * (pageMeta.pageSize || 10)
    const lastPageIndex = firstPageIndex + (pageMeta.pageSize || 10)
    return documents.slice(firstPageIndex, lastPageIndex)
  }, [documents, pageMeta.page, pageMeta.pageSize])

  return (
    <div className="p-4 w-full">
      <WithPaginator
        data={pageData}
        initialPageSize={10}
        totalItems={documents.length}
        onPageMetaChange={onPageMetaChange}
      >
        {pagedData => (
          <DataTable
            data={pagedData}
            headers={[
              { label: 'ID', key: 'id' },
              { label: 'Title', key: 'title' },
              { label: 'Type', key: 'type' },
              { label: 'Path', key: 'path' },
              { label: 'Diploma path', key: 'diplomaPath' },
              { label: 'Created At', key: 'createdAt' },
              ...(!isStudent
                ? [
                    { label: 'Name', key: 'name' },
                    { label: 'Email', key: 'email' },
                    { label: 'Actions', key: 'actions' },
                  ]
                : []),
            ]}
          >
            {data =>
              data.map((item, idx) => (
                <tr className="hover:bg-gray-100" key={idx}>
                  <td className="py-2 px-4 border-b text-start">{item._id}</td>
                  <td className="py-2 px-4 border-b text-start">
                    {item.plan.title}
                  </td>
                  <td className="py-2 px-4 border-b text-start">{item.type}</td>
                  <td className="py-2 px-4 border-b text-start">
                    {item.path ? (
                      <Button
                        onClick={openFile(item.path)}
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                      >
                        Result
                      </Button>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className="py-2 px-4 border-b text-start">
                    {item.diplomaPath ? (
                      <Button
                        onClick={() => onDownloadDocument(item._id)}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      >
                        Download
                      </Button>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className="py-2 px-4 border-b text-start">
                    {moment(item.createdAt).format(DATE_FORMAT)}
                  </td>
                  {!isStudent && (
                    <>
                      <td className="py-2 px-4 border-b text-start">
                        {item.user?.name}
                      </td>
                      <td className="py-2 px-4 border-b text-start">
                        {item.user?.email}
                      </td>
                      <td className="py-2 px-4 border-b text-start">
                        {item.ready ? (
                          <span className="'inline-block text-black text-sm px-3 py-1 rounded-full bg-green-400'">
                            Accepted
                          </span>
                        ) : (
                          <Button
                            onClick={() => onAccept(item._id)}
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                          >
                            Apply
                          </Button>
                        )}
                      </td>
                    </>
                  )}
                </tr>
              ))
            }
          </DataTable>
        )}
      </WithPaginator>
    </div>
  )
}

export const DocumentsPage: React.FC = () => (
  <DocumentsContextProvider>
    <DocumentsView />
  </DocumentsContextProvider>
)
