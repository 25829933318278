import React, { createContext, useCallback, useMemo, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { editPath, usersPath } from '@/utils'
import { IUser, TFunc } from '@/types'
import { useGetUsers } from '@/apis'

interface IUsersContext {
  users: IUser[]
  onGetUsers: TFunc
  selectedUser?: IUser
  onEdit: (_user: IUser) => void
}

export const UsersContext = createContext<IUsersContext>({
  users: [],
  onEdit: () => undefined,
  onGetUsers: () => undefined,
})

export const UsersProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate()
  const [users, setUsers] = useState<IUser[]>([])
  const [selectedUser, setSelectedUser] = useState<IUser>()

  const onUsersSuccess = useCallback((data: { data: IUser[] }) => {
    setUsers(data.data)
  }, [])

  const [getUsers] = useGetUsers(onUsersSuccess)

  const onGetUsers = useCallback(() => {
    getUsers()
  }, [getUsers])

  const onEdit = useCallback(
    (user: IUser) => {
      setSelectedUser(user)
      navigate(`${usersPath}/${editPath}`)
    },
    [navigate],
  )

  const value = useMemo(
    () => ({ users, selectedUser, onEdit, onGetUsers }),
    [users, selectedUser, onEdit, onGetUsers],
  )

  return (
    <UsersContext.Provider value={value}>
      {children ?? <Outlet />}
    </UsersContext.Provider>
  )
}
