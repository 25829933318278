import React from 'react'

import { DataTable, WithPaginator } from '@/components'
import { useTasksHistory } from '@/apis'
import { useAuth } from '@/hooks'

import { HistoryRow } from './components'

const headers = [
  { label: 'Name', key: 'name' },
  { label: 'Email', key: 'email' },
  { label: 'Affiliate', key: 'affiliate' },
  { label: 'Title', key: 'title' },
  { label: 'Transcript path', key: 'path' },
  { label: 'Diploma path', key: 'diplomaPath' },
  { label: 'Created At', key: 'createdAt' },
  { label: 'Actions', key: 'actions' },
]

export const ProctorHistoryPage: React.FC = () => {
  const { me } = useAuth()

  const { onPageMetaChange, pageMeta, data, total } = useTasksHistory(!!me?._id)

  return (
    <div className="p-4 w-full">
      <WithPaginator
        data={data}
        totalItems={total}
        initialPageSize={pageMeta.pageSize}
        onPageMetaChange={onPageMetaChange}
      >
        {pagedData => (
          <DataTable data={pagedData} headers={headers}>
            {data => {
              return data.map((item, idx) => <HistoryRow key={idx} {...item} />)
            }}
          </DataTable>
        )}
      </WithPaginator>
    </div>
  )
}
