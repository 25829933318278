import React, { ReactNode } from 'react'

import { Button } from '@/components'

import { HelpData } from '../mock/helpData'

interface HelpItem {
  img: string
  title: string
  desc: ReactNode
}

export const Help: React.FC = () => {
  return (
    <section className="help-section container">
      <h2>How You Can Help</h2>

      <div className="help-items">
        {HelpData.map((item: HelpItem, index: number) => {
          return (
            <div className="help-item" key={index}>
              <div className="help-img">
                <img src={item.img} alt={item.title} />
              </div>
              <h3>{item.title}</h3>
              {item.desc}
            </div>
          )
        })}
      </div>

      <div className="help-btn">
        <div className="button-style">
          <Button type="button">Get Information</Button>
        </div>
      </div>
    </section>
  )
}
