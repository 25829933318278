import {
  IPlan,
  IResponse,
  IStartEnroll,
  IPlanResponse,
  IQuizActionPayload,
  ISinglePlanResponse,
  IStartedItemsResponse,
  IUpdateQuizAccessibilityPayload,
  IFinishQuizPayload,
} from '@/types'

import { useLazyGet, usePost, usePut, useDelete } from '../utils/wrapper'
import endpoints from '../endpoints'

export const useMyQuizzes = (
  onSuccess: (_data: IStartedItemsResponse) => void,
) => {
  return useLazyGet(endpoints.myQuizzes, undefined, {
    onSuccess,
  })
}

export const useQuizzes = (onSuccess: (_data: IPlanResponse) => void) => {
  return useLazyGet(endpoints.quizzes, undefined, {
    onSuccess,
  })
}

export const useCreateQuiz = () => {
  return usePost<Omit<IPlan, '_id'>, IResponse>(endpoints.createQuiz, undefined)
}

export const useUpdateQuiz = () => {
  return usePut<IPlan, IResponse>(endpoints.updateQuiz, undefined)
}

export const useStartQuiz = () => {
  return usePost<IQuizActionPayload, IStartEnroll>(
    endpoints.startQuiz,
    undefined,
  )
}

export const useDeleteQuiz = () => {
  return useDelete<IQuizActionPayload, IResponse>(
    endpoints.deleteQuiz,
    undefined,
  )
}

export const useFinishQuiz = () => {
  return usePost<IFinishQuizPayload, IResponse>(endpoints.finishQuiz, undefined)
}

export const useGetQuizById = (
  onSuccess: (_data: ISinglePlanResponse) => void,
) => {
  return useLazyGet<IQuizActionPayload, ISinglePlanResponse>(
    endpoints.getQuizById,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useStudents = (onSuccess: (_data: any) => void) => {
  return useLazyGet(endpoints.affiliateStudents, undefined, {
    onSuccess,
  })
}

export const useUpdateQuizAccessibility = () => {
  return usePut<IUpdateQuizAccessibilityPayload, IResponse>(
    endpoints.updateQuizAccessibility,
  )
}

export const useAddQuizToAffiliate = () => {
  return usePost<IQuizActionPayload, IResponse>(endpoints.addQuiz)
}

export const useRemoveQuizFromAffiliate = () => {
  return usePost<IQuizActionPayload, IResponse>(endpoints.removeQuiz)
}

export const useMergeAffiliateQuiz = () => {
  return usePost<IQuizActionPayload, IResponse>(endpoints.mergeCourse)
}
