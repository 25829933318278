import { IRole, SectionTypes, Templates, TSocial } from '@/types'
import moment from 'moment'
import * as yup from 'yup'

export const loginFormSchema = yup
  .object()
  .shape({
    email: yup.string().email().required(),
    password: yup.string().required().min(6),
  })
  .required()

export const signUpFormSchema = yup
  .object()
  .shape({
    role: yup
      .number()
      .oneOf(Object.values(IRole).filter(_v => typeof _v === 'number'))
      .required(),
    email: yup.string().email().required(),
    password: yup.string().required().min(6),
    name: yup.string().required().min(3).max(15),
  })
  .required()

export const forgotFormSchema = yup
  .object()
  .shape({
    email: yup.string().email().required(),
  })
  .required()

export const resetFromSchema = yup
  .object()
  .shape({
    password: yup.string().required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), undefined], 'Passwords must match')
      .required(),
  })
  .required()

const imageSchema = yup
  .mixed()
  .nullable()
  .test('fileType', 'Invalid image format', async function (value: any) {
    const supportedFormats = ['image/jpeg', 'image/png', 'image/svg']

    if (value?.length && !supportedFormats.includes(value.type)) {
      return this.createError({
        message: 'Only JPEG, PNG, and SVG formats are supported',
      })
    }

    return true
  })

export const editProfSchema = yup
  .object()
  .shape({
    bio: yup.string().optional(),
    name: yup.string().required().min(1).max(255),
    city: yup.string().required(),
    email: yup.string().email().required(),
    state: yup.string().required(),
    gender: yup.string().required(),
    zipCode: yup.string().required(),
    address: yup.string().required(),
    dateOfBirth: yup.string().required(),
    phoneNumber: yup.string().required(),
    educationalBackground: yup.string().required(),
    photo: yup.object().shape({
      path: yup.string(),
      file: imageSchema,
    }),
  })
  .required()

export const createRoleSchema = yup
  .object()
  .shape({
    userId: yup.string().required(),
    roles: yup
      .array()
      .of(
        yup.number().oneOf(
          Object.values(IRole).filter(_v => typeof _v === 'number'),
          'Invalid role',
        ),
      )
      .min(1)
      .required(),
  })
  .required()

export const createConsultationSchema = yup
  .object()
  .shape({
    firstName: yup.string().required().min(1).max(255),
    lastName: yup.string().required().min(1).max(255),
    email: yup.string().email().required(),
    phoneNumber: yup
      .string()
      .required()
      .matches(/^\+?[0-9]+$/, 'Phone number must be numeric')
      .min(10, 'Phone number must be at least 10 digits')
      .max(15, 'Phone number must not exceed 15 digits'),
  })
  .required()

export const createAffiliateBrandSchema = yup
  .object()
  .shape({
    logo: imageSchema,
    fontFamily: yup.string().required(),
    color: yup
      .object()
      .shape({
        title: yup.string().required(),
        menuItem: yup.string().required(),
        description: yup.string().required(),
        activeButton: yup.string().required(),
      })
      .required('Color required'),
  })
  .required()

export const createAffiliateSchema = yup
  .object()
  .shape({
    name: yup.string().required('Name is a required field').min(1).max(255),
    companyName: yup.string().max(255),
    postCode: yup.string().matches(/^\d{5}$/, 'Please enter a valid post code'),
    websiteUrl: yup.string(),
    subDomain: yup
      .string()
      .required('Subdomain is required')
      .matches(/^[a-zA-Z0-9][a-zA-Z0-9-]*$/, 'Please enter a valid URL'),
    companyNumber: yup
      .string()
      .matches(/^\+?[0-9]+$/, 'Phone number must be numeric')
      .min(10, 'Phone number must be at least 10 digits')
      .max(15, 'Phone number must not exceed 15 digits'),
    contact: yup
      .object()
      .shape({
        firstName: yup
          .string()
          .required('First Name is a required field')
          .min(1)
          .max(255),
        lastName: yup
          .string()
          .required('Second Name is a required field')
          .min(1)
          .max(255),
        phoneNumber: yup
          .string()
          .required('Phone number is a required field')
          .matches(/^\+?[0-9]+$/, 'Phone number must be numeric')
          .min(10, 'Phone number must be at least 10 digits')
          .max(15, 'Phone number must not exceed 15 digits'),
        email: yup.string().email().required('Email is a required field'),
        mailingAddress: yup
          .string()
          .required('Mailing address is a required field')
          .min(1)
          .max(255),
      })
      .required('Contact required'),
    bankName: yup
      .string()
      .required('Bank name is a required field')
      .min(1)
      .max(255),
    bankAddress: yup
      .string()
      .required('Billing address is a required field')
      .min(1)
      .max(255),
    bankBranchAddress: yup
      .string()
      .required('Branch address is a required field')
      .min(1)
      .max(255),
  })
  .required()

export const createAffiliateAdminSchema = createAffiliateSchema.concat(
  yup
    .object()
    .shape({
      email: yup.string().email().required(),
    })
    .required(),
)

export const createPlanForm = yup
  .object()
  .shape({
    visible: yup.string().required(),
    price: yup.number().min(0).required(),
    title: yup.string().required(),
    description: yup.string().required(),
  })
  .required()

export const feedbackForm = yup
  .object()
  .shape({
    comment: yup.string().required().min(32),
  })
  .required()

export const paymentMethodSchema = yup
  .object()
  .shape({
    cvc: yup
      .object()
      .shape({
        complete: yup.boolean().oneOf([true], 'CVC required'),
        empty: yup.boolean(),
      })
      .required('CVC required'),
    number: yup
      .object()
      .shape({
        complete: yup.boolean().oneOf([true], 'Card number required'),
        empty: yup.boolean(),
      })
      .required('Card number required'),
    expiry: yup
      .object()
      .shape({
        complete: yup.boolean().oneOf([true], 'Expiry required'),
        empty: yup.boolean(),
      })
      .required('Expiry required'),
  })
  .required()

export const referenceForm = yup
  .object()
  .shape({
    name: yup.string().required('Name required'),
    email: yup.string().email().required(),
    phone: yup.string().required(),
  })
  .required()

export const educationForm = yup
  .object()
  .shape({
    degree: yup.string().required('Degree required'),
    institution: yup.string().required('Institution required'),
    fieldOfStudy: yup.string().required('Field Of Study required'),
    graduationYear: yup.date().required('Graduation year required'),
  })
  .required()

export const honoraryDoctorateForm = yup
  .object()
  .shape({
    name: yup.string().required('Name required'),
    dob: yup
      .date()
      .required('Birth date required')
      .max(new Date(moment().subtract(23, 'year').format('YYYY-MM-DD'))),
    gender: yup.string().required('Gender'),
    email: yup.string().email().required('Email'),
    phone: yup.string().required('Phone'),
    paymentEmail: yup.string().email().required('Payment email'),
    professionalBackground: yup
      .string()
      .required('Professional background required'),
    detailedProposal: yup.string().required('Detailed proposal required'),
  })
  .required()

export const updateProfileForm = yup
  .object()
  .shape({
    photo: yup.mixed().optional(),
    email: yup.string().email().required(),
    name: yup.string().required().min(1).max(255),
  })
  .required()

export const createCalendarForm = yup
  .object()
  .shape({
    location: yup.string().required().min(6),
    to: yup.date().required().min(new Date()),
    from: yup.date().required().min(new Date()),
  })
  .required()

const sectionBlockSchema = yup.object({
  image: yup.object().shape({
    path: yup.string(),
    file: imageSchema.when('path', {
      is: () => undefined,
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
  }),
  title: yup.string(),
  description: yup.string().optional(),
})

const sectionSchema = yup.object({
  title: yup.string(),
  videoURL: yup.string(),
  image: yup.object().shape({
    path: yup.string(),
    file: imageSchema.when('path', {
      is: () => undefined,
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
  }),
  description: yup.string(),
  blocks: yup.array().of(sectionBlockSchema),
  type: yup.string().oneOf(Object.values(SectionTypes)).required(),
})

const socialSchema = yup.object({
  link: yup.string(),
  type: yup.string().oneOf(Object.values(TSocial)).required(),
})

export const templateSchema = yup.object({
  id: yup.string().required(),
  image: yup.object().shape({
    path: yup.string(),
    file: imageSchema.when('path', {
      is: () => undefined,
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
  }),
  title: yup.string().required(),
  mainVideo: yup.string().required(),
  description: yup.string().required(),
  socials: yup.array().of(socialSchema).required(),
  sections: yup.array().of(sectionSchema).required(),
  name: yup.string().oneOf(Object.values(Templates)).required(),
})

export const chooseProctorForm = yup
  .object()
  .shape({
    proctor: yup.string().required(),
  })
  .required()
