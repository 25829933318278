import React, { useCallback } from 'react'
import { SubmitHandler } from 'react-hook-form'

import { IOption, TChooseProctorSchema, TFunc } from '@/types'
import { Button, Form, Modal, Select } from '@/components'
import { chooseProctorForm } from '@/utils'
import { useHookForm } from '@/hooks'
import { useProctors } from '@/apis'

interface IChooseProctor {
  onClose?: TFunc
  onChoose: (_proctor: string) => void
}

export const ChooseProctor: React.FC<IChooseProctor> = ({
  onClose,
  onChoose,
}) => {
  const { data } = useProctors()

  const {
    handler: {
      trigger,
      setValue,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(chooseProctorForm)

  const onSubmit: SubmitHandler<TChooseProctorSchema> = useCallback(
    async payload => {
      onChoose(payload.proctor)
      //
    },
    [onChoose],
  )

  const onSelect = useCallback(
    (_option: IOption<string>) => {
      setValue('proctor', _option.value)
      trigger('proctor')
    },
    [setValue, trigger],
  )

  return (
    <Modal isOpen canClose={!!onClose} onClose={onClose}>
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="sm:flex flex-col sm:items-start"
        >
          <Select
            label="Proctor"
            onChange={onSelect}
            className="w-full mb-2"
            placeHolder="Choose proctor"
            value={getValues('proctor')}
            error={errors?.proctor?.message}
            options={(data?.data ?? []).map(_v => ({
              value: _v._id,
              label: _v.name,
            }))}
          />
          <div className="flex w-full justify-end">
            <Button
              type="submit"
              className="bg-green-300 text-black py-2 px-4 mb-5 rounded cursor-pointer hover:bg-green-500"
            >
              Choose
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}
