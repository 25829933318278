import React, { useCallback, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { Icon } from '@/components'

import { Menu } from './components'

import logoImg from '@/assets/images/logo.svg'

export const AppHeader: React.FC = () => {
  const [show, setShow] = useState(false)

  const toggle = useCallback(() => {
    setShow(prev => !prev)
  }, [])

  return (
    <header>
      <div className="header-section">
        <NavLink to="/">
          <div className="logo-img">
            <img src={logoImg} alt="logo img" />
          </div>
        </NavLink>
        <div>
          <Menu show={show} setShow={setShow} />
          <Icon
            onClick={toggle}
            className="burger"
            name={show ? 'close' : 'menu'}
          />
        </div>
      </div>
    </header>
  )
}
