import Flexible from '@/assets/images/flaxible-img.webp'
import BannerImg from '@/assets/images/banner-img.webp'

export const BannerData = {
  title: 'Instant Certification',
  desc: 'After successful course completion, you are able to download your certificate or diploma. Students are able to loginPage into their account to view certificates, and completed courses.',
  img: BannerImg,
}

export const FlexibleData = {
  title: 'Flexible and 100% Online',
  desc: 'ABG offers a online High SchoolPricing Diploma Program. Choose from a wide variety of programs at the, bachelor’s, master’s level, most of which are 100% online',
  img: Flexible,
}
