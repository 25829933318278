import React from 'react'

export const MapContent: React.FC = () => {
  return (
    <div>
      <iframe
        width="100%"
        height="205"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBnqRKZUWWJ3b1bJSLcGKVYXbndZYn-CAI&amp;q=128+Mallory%2C+Jersey+City%2C+NJ+07304"
      />
    </div>
  )
}
