import React from 'react'

// interface CountItem {
//   value: string
//   text: string
// }

export const CountInfo: React.FC = () => {
  return (
    <section className="count-info">
      <div className="count-items container">
        {/* {CountInfoData.map((countItem: CountItem, index: number) => {
          return (
            <div className="count-info-item" key={index}>
              <h2>{countItem.value}</h2>
              <strong>{countItem.text}</strong>
            </div>
          )
        })} */}
      </div>
    </section>
  )
}
