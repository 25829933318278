import {
  IUser,
  IResponse,
  IUploadResponse,
  IUpdateProfilePayload,
  IChooseRolePayload,
} from '@/types'

import { useLazyGet, usePost, usePut } from '../utils/wrapper'
import endpoints from '../endpoints'

export const useGetMe = (onSuccess: (_data: IUser) => void) => {
  return useLazyGet(endpoints.me, undefined, {
    onSuccess,
  })
}

export const useGetUsers = (onSuccess: (_data: { data: IUser[] }) => void) => {
  return useLazyGet(endpoints.users, undefined, {
    onSuccess,
  })
}

export const useUploadAvatar = () => {
  return usePost<FormData, IUploadResponse>(endpoints.uploadAvatar, undefined)
}

export const useUpdateProfile = () => {
  return usePut<IUpdateProfilePayload, IResponse>(endpoints.updateProfile)
}

export const useChooseRole = () => {
  return usePut<IChooseRolePayload, IUser>(endpoints.chooseRole, undefined)
}
