import React, { useCallback, useState } from 'react'

import { Button } from '@/components'
import { CreateConsultation } from '@/layout/MainLayout/components'

export const Consultation: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <section className="flex justify-center p-6">
      <div className="help-btn">
        <div className="button-style">
          <Button type="button" onClick={() => setIsOpen(true)}>
            Sign up to enroll your school with unitylearn
          </Button>
        </div>
      </div>
      {isOpen && <CreateConsultation onClose={onClose} />}
    </section>
  )
}
