import React, { useCallback, useState } from 'react'
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import {
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js'
import { Controller, SubmitHandler } from 'react-hook-form'

import { Button, Form, FormError, Modal } from '@/components'
import { useAuth, useH5P, useHookForm } from '@/hooks'
import { TPaymentMethodSchema } from '@/types'
import { paymentMethodSchema } from '@/utils'

import { CardCvcEl, CardExpiryEl, CardNumberEl } from './stripeCardElements'

export const CheckoutForm: React.FC<{
  price: number
  onClose?: () => void
  clientSecret: string
  onSuccess?: () => void
}> = ({ price, clientSecret, onClose, onSuccess }) => {
  const { me } = useAuth()
  const stripe = useStripe()
  const elements = useElements()
  const { onPaymentSuccess } = useH5P()
  const [error, setError] = useState<string | undefined>(undefined)

  const {
    handler: {
      control,
      trigger,
      clearErrors,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(paymentMethodSchema)

  const onSubmit: SubmitHandler<TPaymentMethodSchema> =
    useCallback(async () => {
      if (!stripe || !elements) {
        return
      }

      const cardElement = elements.getElement(CardNumberElement)

      if (!cardElement) {
        setError('Card Element not found')
        return
      }

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: me?.name,
          },
        },
      })
      if (result.paymentIntent?.status === 'succeeded') {
        if (onSuccess) {
          onSuccess()
        } else {
          onPaymentSuccess()
        }
      } else {
        setError(result.error?.message || undefined)
      }
    }, [stripe, elements, clientSecret, me?.name, onSuccess, onPaymentSuccess])

  return (
    <Modal isOpen canClose={!!onClose} onClose={onClose}>
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="sm:flex flex-col sm:items-start"
        >
          <Controller
            control={control}
            name="number"
            render={({ field: { name, onChange } }) => (
              <CardNumberEl
                onChange={(ev: StripeCardNumberElementChangeEvent) => {
                  onChange(ev)
                  trigger(name)
                  clearErrors(name)
                }}
                error={errors.number?.complete?.message}
              />
            )}
          />
          <div className="sm:flex sm:items-start justify-between w-full">
            <Controller
              name="expiry"
              control={control}
              render={({ field: { name, onChange } }) => (
                <CardExpiryEl
                  onChange={(ev: StripeCardExpiryElementChangeEvent) => {
                    onChange(ev)
                    trigger(name)
                    clearErrors(name)
                  }}
                  error={errors.expiry?.complete?.message}
                />
              )}
            />
            <Controller
              name="cvc"
              control={control}
              render={({ field: { name, onChange } }) => (
                <CardCvcEl
                  onChange={(ev: StripeCardExpiryElementChangeEvent) => {
                    onChange(ev)
                    trigger(name)
                    clearErrors(name)
                  }}
                  error={errors.cvc?.complete?.message}
                />
              )}
            />
          </div>
          <div className="flex w-full justify-end">
            <Button
              type="submit"
              className="bg-green-300 text-black py-2 px-4 mb-5 rounded cursor-pointer hover:bg-green-500"
            >
              Pay ${price}
            </Button>
          </div>
          <FormError error={error} />
        </Form>
      </div>
    </Modal>
  )
}
